const Paths = {
  Portal: {
    Index: "/:handleKey",
    Home: "/:handleKey",
    CampaignList: "/campaign",
    NotFound: "/page/notfound",
  },

}

export const makeRelativePath = (rootPath: string) => {
  return (path: string, suffix = "") => path.replace(rootPath, "").concat(suffix);
}

export default Paths
