import { createSvgIcon } from '@mui/material/utils';

const PaginationLeft = createSvgIcon(
  <svg viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33414 0.448655C8.72467 0.839179 8.72467 1.47234 8.33414 1.86287L3.20791 6.9891L8.33414 12.1153C8.72467 12.5058 8.72467 13.139 8.33414 13.5295C7.94362 13.9201 7.31045 13.9201 6.91993 13.5295L1.08659 7.6962C0.69607 7.30568 0.69607 6.67251 1.08659 6.28199L6.91993 0.448655C7.31045 0.0581307 7.94362 0.0581307 8.33414 0.448655Z" fill="currentColor" />
  </svg>
  ,
  'PaginationLeft'
);

export default PaginationLeft;