import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, TextField, useTheme, CircularProgress } from "@mui/material";
import { ArrowRight, IconX } from "src/assets";
import { Typography } from "src/main/components";
import { IntlFormatter, createStyles, joinSx } from "src/main/utils";

interface ConfirmDialogProps extends Omit<DialogProps, "open"> {
  username: string;
  openFlag?: boolean;
  triggerSubmit: () => void;
  onUsernameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
}
const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { username, openFlag = false, onClose, triggerSubmit, onUsernameChange, loading, ...dialogProps } = props;
  const theme = useTheme();

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={openFlag}
      onClose={_onClose}
      sx={{ ".MuiDialog-paper": { overflow: "visible" } }
      }>
      <Box sx={{
        position: "absolute",
        zIndex: 10000,
        right: -10, top: -10,
      }}>
        <IconButton
          onClick={() => _onClose({}, "escapeKeyDown")}
          sx={
            {
              backgroundColor: "white", borderRadius: 10, boxShadow: 8, p: .5,
              ":hover": {
                backgroundColor: "white"
              }
            }
          }>
          <IconX fontSize="small" />
        </IconButton>
      </Box>
      <DialogTitle textAlign="center" >
        {IntlFormatter.getMessage("dialog.confirm_username", "Confirm Username")}
      </DialogTitle>
      <DialogContent>
        <Typography formatId="dialog.credit_send_to" textAlign="center" variant="body2" color="text.secondary" lineHeight={1.25}>
          Credits will be sent to:
        </Typography>
        <Box
          mt={1}
          p={2}
          borderRadius={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "#A44EE81A"
          }}
        >
          <TextField
            sx={joinSx(styles.textField)}
            hiddenLabel
            name="secretCode"
            value={username}
            onChange={onUsernameChange}
            variant="outlined"
            onKeyPress={(event) => {
              if (event.key === " ") {
                event.preventDefault(); // Prevent adding space
              }
            }}
            InputProps={{ sx: { backgroundColor: "white" } }}
            InputLabelProps={{ sx: { color: "text.secondary" } }}
          />
        </Box>
        <Box >
          <Typography textAlign="center" variant="body2" color="text.secondary">
            {IntlFormatter.getMessage("dialog.check_username", "Please check your username carefully.")} <Typography formatId="dialog.incorrect_username" variant="body2" color="error" pl={.6}>Incorrect usernames will be disqualified.</Typography>
          </Typography>

        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 4 }}>
        <Button
          disabled={!username || loading}
          startIcon={loading ? <CircularProgress size={20} sx={{ color: "#ffffff" }} /> : <ArrowRight />}
          variant="contained"
          sx={{ background: theme.palette.primary.gradient, fontWeight: 700, boxShadow: 10 }}
          onClick={() => {
            triggerSubmit();
          }
          }
        >
          {IntlFormatter.getMessage("dialog.proceed", "Proceed")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = createStyles({
  textField: {
    '.MuiFilledInput-root': {
      borderRadius: 2
    }
  }
});

export default ConfirmDialog;
