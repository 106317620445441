import { TextField, TextFieldProps } from "@mui/material";
import { createStyles, joinSx } from "src/main/utils";

type InputFieldProps = TextFieldProps & {
}

const InputField = (props: InputFieldProps) => {
  const { ...rest } = props
  return (
    <TextField
      fullWidth
      InputLabelProps={{
        shrink: true,
      }}
      sx={joinSx(styles.input, props.sx)}
      {...rest}
    />
  )
}

const styles = createStyles({
  input: {
    "& .MuiInputBase-input": {
      padding: "12px 10px 12px 10px",
    },
    "& textarea.MuiInputBase-inputMultiline": {
      padding: "0px", overFlow: "scroll", minHeight: "100px", maxHeight: "100px"
    },
    "& .MuiInputBase-multiline": {
      padding: "12px 10px 12px 10px",
    },
  }
})

export default InputField;
