import { createSvgIcon } from '@mui/material/utils';

const PaginationFirst = createSvgIcon(
  <svg viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.82387 0.625432C8.11676 0.918325 8.11676 1.3932 7.82387 1.68609L2.52087 6.9891L7.82387 12.2921C8.11676 12.585 8.11676 13.0599 7.82387 13.3528C7.53097 13.6457 7.0561 13.6457 6.76321 13.3528L0.929875 7.51943C0.636982 7.22653 0.636982 6.75166 0.929875 6.45877L6.76321 0.625432C7.0561 0.332538 7.53097 0.332538 7.82387 0.625432ZM14.4905 0.625432C14.7834 0.918325 14.7834 1.3932 14.4905 1.68609L9.18753 6.9891L14.4905 12.2921C14.7834 12.585 14.7834 13.0599 14.4905 13.3528C14.1976 13.6457 13.7228 13.6457 13.4299 13.3528L7.59654 7.51943C7.30365 7.22653 7.30365 6.75166 7.59654 6.45877L13.4299 0.625432C13.7228 0.332538 14.1976 0.332538 14.4905 0.625432Z" fill="currentColor" />
  </svg>

  ,
  'PaginationFirst'
);

export default PaginationFirst;