import { BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query";
import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { createApi } from "@reduxjs/toolkit/query/react";
import { queryWithoutAuth } from "../base";
import { getCouponInformation, getCouponPage, getCouponStatus, redeemCoupon, subscribe } from "./Coupon";
import { parseMeta } from "src/main/utils/parseMeta";


export type PublicTagType = "coupon";
export const require200Status = (response: Response) => response.status === 200;
export type BuilderProp = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>, PublicTagType, "publicApi">

const publicApi = createApi({
  reducerPath: "publicApi",
  baseQuery: queryWithoutAuth,
  endpoints: (builder) => ({
    getCouponInformation: getCouponInformation(builder),
    getCouponStatus: getCouponStatus(builder),
    redeemCoupon: redeemCoupon(builder),
    getCouponPage: getCouponPage(builder),
    getCampaignList: builder.query({
      query: (meta) => `/campaign/list?${parseMeta(meta)}`
    }),
    subscribe : subscribe(builder),
  })
})

export default publicApi;