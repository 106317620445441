import { createSvgIcon } from '@mui/material/utils';

const IconX = createSvgIcon(
    <svg viewBox="-3 -3 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.292893 0.298753C0.683417 -0.0917717 1.31658 -0.0917717 1.70711 0.298753L7 5.59165L12.2929 0.298753C12.6834 -0.0917717 13.3166 -0.0917717 13.7071 0.298753C14.0976 0.689277 14.0976 1.32244 13.7071 1.71297L8.41421 7.00586L13.7071 12.2988C14.0976 12.6893 14.0976 13.3224 13.7071 13.713C13.3166 14.1035 12.6834 14.1035 12.2929 13.713L7 8.42007L1.70711 13.713C1.31658 14.1035 0.683417 14.1035 0.292893 13.713C-0.0976311 13.3224 -0.0976311 12.6893 0.292893 12.2988L5.58579 7.00586L0.292893 1.71297C-0.0976311 1.32244 -0.0976311 0.689277 0.292893 0.298753Z" fill="currentColor"/>
    </svg>,
    'IconX'
);

export default IconX;

