import { Container, useTheme } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren {

}

const Content: React.FC<ContentProps> = (props) => {
  const { children } = props;
  const theme = useTheme();
  return (
    <>
      <style>
        {`body {background: ${theme.palette.primary.gradient}}`}
      </style>
      <Container maxWidth="sm" sx={styles.root}>
        {children}
      </Container >
    </>
  );
};

const styles = createStyles({
  root: {
  },
});

export default Content;
