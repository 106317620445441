import { createSvgIcon } from '@mui/material/utils';

const Star = createSvgIcon(
  <svg viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_841_1914)">
      <path d="M7.09585 23.0195C6.51619 23.3175 5.85984 22.7968 5.97654 22.1316L7.22113 15.0374L1.93763 10.0024C1.44386 9.53186 1.69946 8.67152 2.36095 8.57755L9.70866 7.53374L12.9848 1.04393C13.28 0.459316 14.0794 0.459316 14.3745 1.04393L17.6507 7.53374L24.9984 8.57755C25.6599 8.67152 25.9155 9.53186 25.4217 10.0024L20.1382 15.0374L21.3828 22.1316C21.4995 22.7968 20.8432 23.3175 20.2635 23.0195L13.6797 19.6356L7.09585 23.0195Z" fill="url(#paint0_linear_841_1914)" />
      <path d="M5.7303 22.0884C5.58258 22.9304 6.42311 23.6464 7.21013 23.2419L13.6797 19.9167L20.1492 23.2419C20.9363 23.6464 21.7768 22.9304 21.6291 22.0884L20.4076 15.126L25.5942 10.1834C26.2273 9.58003 25.9179 8.45566 25.0336 8.33003L17.8151 7.30458L14.5977 0.931263C14.2101 0.163537 13.1492 0.163537 12.7617 0.931263L9.54429 7.30458L2.32578 8.33003C1.44144 8.45566 1.13203 9.58003 1.76516 10.1834L6.95176 15.126L5.7303 22.0884Z" stroke="#FFAA06" strokeWidth="0.5" />
    </g>
    <defs>
      <filter id="filter0_d_841_1914" x="0.181641" y="0.105469" width="26.9961" height="26.5053" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.105882 0 0 0 0 0.141176 0 0 0 0 0.172549 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_841_1914" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_841_1914" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_841_1914" x1="13.6797" y1="0.605469" x2="13.6797" y2="24.9198" gradientUnits="userSpaceOnUse">
        <stop offset="0.114583" stopColor="#FFF857" />
        <stop offset="0.447917" stopColor="#E69220" />
        <stop offset="0.49209" stopColor="#DE720F" />
        <stop offset="1" stopColor="#FFF857" />
      </linearGradient>
    </defs>
  </svg>,
  'Star'
);

export default Star;
