import { BuilderProp, require200Status } from "src/main/api/public";
import { QueryResult } from "src/main/types";

export interface CreateSubscribeRequest {
  email: string;
}

export interface CreateSubscribeResponse {
}

export const subscribe = (builder: BuilderProp) => {
  return builder.mutation<CreateSubscribeResponse, CreateSubscribeRequest>({
    query: (payload) => ({
      url: "/subscribe",
      method: "POST",
      body: payload,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<CreateSubscribeResponse>) => {
      return response
    }
  })
}