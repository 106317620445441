import queryString from "query-string";
import { SimpleMap, CouponRequest } from "./type";

export const parseMeta = (meta: CouponRequest | undefined) => {
  if (!meta) return "";
  let query: SimpleMap = {};
  Object.entries(meta).forEach(([key, val], index) => {
    if (key === "count") return;

    if (typeof val === "string" || typeof val === "number")
      query[key] = val.toString();
  })

  return queryString.stringify(query).replace("?", "");
}
