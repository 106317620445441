import { createSvgIcon } from '@mui/material/utils';

const Person = createSvgIcon(
  <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.68945 12.4941C1.68945 12.4941 0.689453 12.4941 0.689453 11.4941C0.689453 10.4941 1.68945 7.49414 6.68945 7.49414C11.6895 7.49414 12.6895 10.4941 12.6895 11.4941C12.6895 12.4941 11.6895 12.4941 11.6895 12.4941H1.68945Z" fill="url(#paint0_linear_678_863)" />
    <path d="M6.68945 6.49414C8.34631 6.49414 9.68945 5.15099 9.68945 3.49414C9.68945 1.83729 8.34631 0.494141 6.68945 0.494141C5.0326 0.494141 3.68945 1.83729 3.68945 3.49414C3.68945 5.15099 5.0326 6.49414 6.68945 6.49414Z" fill="url(#paint1_linear_678_863)" />
    <defs>
      <linearGradient id="paint0_linear_678_863" x1="0.689453" y1="12.4941" x2="13.5344" y2="11.4332" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6A0FDE" />
        <stop offset="1" stopColor="#579AFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_678_863" x1="0.689453" y1="12.4941" x2="13.5344" y2="11.4332" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6A0FDE" />
        <stop offset="1" stopColor="#579AFF" />
      </linearGradient>
    </defs>
  </svg>,
  'Person'
);

export default Person;
