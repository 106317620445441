import { createSvgIcon } from "@mui/material/utils";

export const ThIcon = createSvgIcon(
  <svg viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_553_1049)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.419922 0.18103H16.6839V17.2582H0.419922V0.18103Z" fill="url(#paint0_linear_553_1049)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.419922 0.18103H16.6839V3.59646H0.419922V0.18103Z" fill="url(#paint1_linear_553_1049)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.419922 13.8428H16.6839V17.2582H0.419922V13.8428Z" fill="url(#paint2_linear_553_1049)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.419922 3.59644H16.6839V13.8427H0.419922V3.59644Z" fill="url(#paint3_linear_553_1049)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.419922 5.87341H16.6839V11.5658H0.419922V5.87341Z" fill="url(#paint4_linear_553_1049)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_553_1049" x1="171.191" y1="0.18103" x2="171.191" y2="256.338" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient id="paint1_linear_553_1049" x1="171.191" y1="0.18103" x2="171.191" y2="51.4125" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F12532" />
        <stop offset="1" stopColor="#EB212E" />
      </linearGradient>
      <linearGradient id="paint2_linear_553_1049" x1="171.191" y1="13.8428" x2="171.191" y2="65.0742" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F12532" />
        <stop offset="1" stopColor="#EB212E" />
      </linearGradient>
      <linearGradient id="paint3_linear_553_1049" x1="171.191" y1="3.59644" x2="171.191" y2="157.291" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient id="paint4_linear_553_1049" x1="171.191" y1="5.87341" x2="171.191" y2="91.2591" gradientUnits="userSpaceOnUse">
        <stop stopColor="#322B6C" />
        <stop offset="1" stopColor="#241F4E" />
      </linearGradient>
      <clipPath id="clip0_553_1049">
        <rect x="0.551758" y="0.719604" width="16" height="16" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>

  ,
  'ThIcon'
);

export default ThIcon;

