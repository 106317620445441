import { Box, Button, Divider, useTheme } from "@mui/material";
import { ColdCoinGif, SilverCoinGif, TreasureBoxGif, SilverTreasureBoxGif } from "src/assets";
import { IntlFormatter, createStyles, joinSx, calculateRemainingTime, formatTime, calculateTotalJackpotAmount, calculateTotalJackpot, formatCurrency } from "src/main/utils";
import { CampaignType } from "src/main/constants";
import React, { useEffect, useState, useMemo } from 'react';
import { Typography } from "src/main/components";

const styles = createStyles({
  button: {
    borderRadius: '4px',
    color: 'primary.contrastText',
    mx: "auto",
    py: .5,
  },
  upcomingButton: {
    background: "#E53460",
    fontWeight: 700,
    ":hover": {
      backgroundColor: "error.dark"
    }
  },
  innerCardBoxShadow: {
    height: "95%",
    my: 1,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 1px 0px;",
  }
})
interface StatsModel {
  totalQuests: string;
}

interface JackpotModel {
  amount: string;
  name: string;
  payCurrency: string;
  remaining: number;
}

interface CouponDetailsModel {
  createdAt: string;
  id: string;
  title: string;
  description?: string;
  handle: string;
  minValue: number;
  maxValue: number;
  payCurrency: string;
  stats: StatsModel;
  start: string;
  winnerLimit: number;
  extendWinner: number;
  maxRedemption: number;
  syncedFakeWinner: boolean;
  type: string;
  jackpots: JackpotModel[];
}

export interface CouponProps extends React.PropsWithChildren {
  couponDetails: CouponDetailsModel;
  status: string;
  onCountdownComplete: () => void;
  myKey: number;
}

const Coupon: React.FC<CouponProps> = (props) => {
  const { couponDetails, status, onCountdownComplete, myKey } = props;
  const theme = useTheme();
  const [remainingTime, setRemainingTime] = useState<number>(calculateRemainingTime(couponDetails.start));

  const totalJackpotAmount = calculateTotalJackpotAmount(couponDetails);
  const totalJackpot = calculateTotalJackpot(couponDetails);

  const totalNormalPrize = useMemo(() => {
    const totalPrize = couponDetails.type === CampaignType.LuckyDraw ? couponDetails.winnerLimit : couponDetails.maxRedemption;
    const extendedWinner = couponDetails.syncedFakeWinner ? couponDetails.extendWinner ?? 0 : 0;
    return totalPrize - totalJackpot + extendedWinner;
  }, [couponDetails, totalJackpot]);

  useEffect(() => {
    if (status !== "upcoming") return;

    if (remainingTime === 0) setRemainingTime(calculateRemainingTime(couponDetails.start));

    let timer: NodeJS.Timeout;
    if (remainingTime) {
      if (remainingTime > 1000) {
        timer = setInterval(() => {
          setRemainingTime(calculateRemainingTime(couponDetails.start));
        }, 1000);
      } else {
        onCountdownComplete(); // when remainingTime reaches 0
      }

      return () => {
        clearInterval(timer);
      };
    }

  }, [remainingTime, couponDetails.start, status, onCountdownComplete]);

  return (
    <Box
      display="flex"
      alignItems="center"
      height="120px"
      overflow="hidden"
      key={myKey}
      mb={1}
    >
      <Box
        display="flex"
        alignItems="center"
        flex={2}
        sx={joinSx(
          styles.innerCardBoxShadow,
          {
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }
        )}
      >
        <Box p={1}>
          <Typography
            variant="subtitle2"
            color={(status === "ended") ? "text.disabled" : ""}
            lineHeight={1.3}
            sx={{
              display: '-webkit-box',
              '-webkit-line-clamp': 2,
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              maxHeight: '3.6rem',
              lineClamp: 2,
              boxOrient: 'vertical',
            }}>
            {couponDetails.title}</Typography>
          <Typography variant="caption" color="text.disabled"
            formatId="campaign.quest_required"
            defaultMessage={`${couponDetails.stats.totalQuests} quests required`}
            formatValues={{ count: couponDetails.stats.totalQuests }}
          >
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        width="15px"
        height="100%"
        sx={
          {
            background: `linear-gradient(270deg, ${(status === "ended") ? "#F4F4F4" : "#F6EEFD"} 47.4%, #FFFFFF 47.41%)`,
          }}
        position="relative"
      >
        <Box sx={
          {
            position: "absolute",
            top: "-5px",
            zIndex: 3,
            backgroundColor: "white",
            width: "15px", height: "15px",
            borderRadius: "0 0 10rem 10rem",
            boxShadow: "inset 0px -1.5px 0 0 #ccc"
          }
        }></Box>
        <Divider sx={{ width: "0px", height: "100%", border: 0, borderLeft: "3px dotted rgba(0, 0, 0, 0.3)" }} orientation="vertical" />
        <Box sx={
          {
            position: "absolute",
            bottom: "-2px",
            zIndex: 4,
            backgroundColor: "white",
            width: "15px", height: "10px",
            borderRadius: "10rem 10rem 0 0 ",
            boxShadow: "inset 0 2px 2px 0  #ccc"
          }
        }></Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        sx={joinSx(
          styles.innerCardBoxShadow,
          {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            backgroundColor: (status === "ended") ? "#F4F4F4" : "#F6EEFD"
          }
        )}
      >
        <Box
          minWidth="120px"
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="center"
          sx={{ flexDirection: 'column', px: 1 }}
        >
          {couponDetails.jackpots.length !== 0 && (
            <Box sx={{ width: '100%' }}>
              <Typography fontSize={10} fontWeight={700} lineHeight='10px' color={(status === "ended") ? "text.disabled" : "text.dark"}
                formatId="lottery.jackpot"
                defaultMessage={`${totalJackpot > 1 && (totalJackpot)} JACKPOT`}
                formatValues={{ number: totalJackpot > 1 ? totalJackpot.toString() : '' }}
              />
              <Box display="flex" alignItems="center" justifyContent="center">
                <Box component="img" src={(status === "ended") ? SilverTreasureBoxGif : TreasureBoxGif} alt="" />
                <Typography variant="subtitle2" pl={.5} color={(status === "ended") ? "text.disabled" : "primary"}>
                  {formatCurrency(totalJackpotAmount, couponDetails.payCurrency)}
                </Typography>
                <Typography fontSize={8} fontWeight={700} color={(status === "ended") ? "text.disabled" : "primary"} pl={.5}>
                  {couponDetails.payCurrency}
                </Typography>
              </Box>
              <Box sx={{ py: .5 }}>
                <Divider sx={{ borderBottomWidth: 2 }} />
              </Box>
            </Box>
          )}
          <Typography fontSize={10} fontWeight={700} lineHeight='10px' color={(status === "ended") ? "text.disabled" : "text.dark"} sx={{ textTransform: 'uppercase' }}
            formatId="campaign.prizes"
            defaultMessage={`${totalNormalPrize} prizes`}
            formatValues={{ number: totalNormalPrize }}
          />

          <Box display="flex" alignItems="center" justifyContent="center">
            <Box component="img" src={(status === "ended") ? SilverCoinGif : ColdCoinGif} alt="" />
            <Typography variant="subtitle2" color={(status === "ended") ? "text.disabled" : "primary"}>
              {couponDetails.minValue === couponDetails.maxValue ? formatCurrency(couponDetails.maxValue, couponDetails.payCurrency) : formatCurrency(couponDetails.minValue, couponDetails.payCurrency) + '-' + formatCurrency(couponDetails.maxValue, couponDetails.payCurrency)}
            </Typography>
            <Typography fontSize={8} fontWeight={700} color={(status === "ended") ? "text.disabled" : "primary"} pl={.5}>
              {couponDetails.payCurrency}
            </Typography>
          </Box>

          <Button
            fullWidth
            sx={joinSx(
              styles.button,
              (status === "upcoming") ? styles.upcomingButton : {
                background: theme.palette.primary.gradient,
                fontWeight: 700,
              }
            )}
          >
            <Typography variant="subtitle2">
              {(status === "upcoming") ? `${formatTime(remainingTime)}` : (status === "ended") ? IntlFormatter.getMessage("campaign.winners", "Winners") : IntlFormatter.getMessage("campaign.enter", "Enter")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>);

}
export default Coupon;