import { fork } from "redux-saga/effects";
import preference from "./preference";
import layout from "./layout";

function* mainSaga() {
  yield fork(preference.saga);
  yield fork(layout.saga);
}

export default mainSaga;
