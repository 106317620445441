import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, capitalize } from "@mui/material";
import { Theme } from "@mui/system";
import React from "react";
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { Typography } from 'src/main/components';
import { RootState } from "src/main/store";
import { ThemeMode } from "src/main/store/preference/types";

interface ToasterProps extends React.PropsWithChildren {
  theme: Theme;
}

const ToasterComponent: React.FC<ToasterProps> = ({ theme }) => {
  const savedTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  return (
    <Toaster
      position="bottom-center"
      reverseOrder={false}
      toastOptions={{
        iconTheme: {
          primary: 'red',
          secondary: '#fff',
        },
        duration: 5000,
        className: "",
        style: {
          backgroundColor: theme.palette.snackbar,
          color: savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText,
          fontWeight: 400,
          fontSize: "14px",
          padding: "16px",
        }
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => {
            return (
              <Box display="flex" sx={{ justifyContent: 'space-between', width: '100%' }}>
                <Box>{icon}</Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}>
                  <Typography formatId={"toast." + t.type} variant="body2" fontWeight={700} pl={1} >
                    {capitalize(t.type)}
                  </Typography>
                  <Typography variant="caption">
                    {message}
                  </Typography>
                </Box>
                <Box>
                  {t.type !== 'loading' && (
                    <IconButton sx={{ color: savedTheme === "dark" ? theme.palette.primary.light : theme.palette.primary.contrastText }} onClick={() => toast.dismiss(t.id)}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            )
          }}
        </ToastBar >
      )}
    </Toaster >
  );
};

export default ToasterComponent;