import { createSvgIcon } from '@mui/material/utils';

const StopWatchActiveIcon = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.5 0.75C5.22386 0.75 5 0.973858 5 1.25C5 1.52614 5.22386 1.75 5.5 1.75H6V2.82094C2.60749 3.30639 0 6.22347 0 9.75C0 13.616 3.13401 16.75 7 16.75C10.866 16.75 14 13.616 14 9.75C14 7.99676 13.3555 6.39415 12.2905 5.16609C12.2948 5.16205 12.2991 5.15793 12.3033 5.15371L12.6569 4.80015L13.0104 5.15371C13.2057 5.34897 13.5223 5.34897 13.7175 5.15371C13.9128 4.95844 13.9128 4.64186 13.7175 4.4466L12.3033 3.03239C12.1081 2.83712 11.7915 2.83712 11.5962 3.03239C11.401 3.22765 11.401 3.54423 11.5962 3.73949L11.9498 4.09305L11.5962 4.4466C11.592 4.45083 11.5879 4.45511 11.5838 4.45945C10.5936 3.60082 9.35986 3.01553 8 2.82094V1.75H8.5C8.77614 1.75 9 1.52614 9 1.25C9 0.973858 8.77614 0.75 8.5 0.75H5.5ZM7.49999 6.34997L7.5 9.75C7.5 9.88261 7.44732 10.0098 7.35355 10.1036C7.25979 10.1973 7.13261 10.25 7 10.25H3.5C3.22386 10.25 3 10.0261 3 9.75C3 9.47386 3.22386 9.25 3.5 9.25H6.5L6.49999 6.34998C6.49999 6.07383 6.72384 5.84998 6.99999 5.84998C7.27613 5.84998 7.49999 6.07383 7.49999 6.34997Z"
            fill="url(#paint0_linear_776_487)"
        />
        <defs>
            <linearGradient id="paint0_linear_776_487" x1="0.558098" y1="16.75" x2="11.3066" y2="16.1951" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6A0FDE" />
                <stop offset="1" stopColor="#579AFF" />
            </linearGradient>
        </defs>
    </svg>,
    'StopWatchActiveIcon'
);

export default StopWatchActiveIcon;
