import React from "react";
import { FormControl, InputLabel, Select, MenuItem, TextFieldProps, OutlinedInput, SelectChangeEvent, IconButton } from "@mui/material";
import { Option } from "src/main/types";
import { AccessTime } from "@mui/icons-material";

type SortbarProps = TextFieldProps & {
  onOptionChange: (option: Option) => void;
  options: Option[];
  defaultSelected: string;
  onOpenTimeDialog?: () => void;
  value?: string;
}

const SortbarComponent: React.FC<SortbarProps> = (props) => {
  const { value, onOptionChange, options, label = "Sort By", placeholder = "sort", onOpenTimeDialog } = props;

  const onChangeHandler = (val: string) => {
    let found = options.find((opt) => opt.value === val);
    if (found) onOptionChange(found);
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel shrink >{label}</InputLabel>
      <Select
        input={<OutlinedInput placeholder={placeholder} notched label={label} />}
        IconComponent={
          onOpenTimeDialog
            ? (props) => <IconButton onClick={() => onOpenTimeDialog()}>
              <AccessTime />
            </IconButton>
            : undefined
        }
        name="sort"
        onChange={(ev: SelectChangeEvent) => { onChangeHandler(ev.target.value) }}
        placeholder={placeholder}
        value={value}
      >
        {options.map((option: Option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}


export default SortbarComponent; 