import { Box, BoxProps, CircularProgress } from "@mui/material";
import React from "react";
import { createStyles, joinSx } from "src/main/utils";

interface LoadingContainerProps extends BoxProps {
  loading?: boolean;
}

const LoadingContainer: React.FC<LoadingContainerProps> = (props) => {
  const { children, loading, sx, ...rest } = props;
  return (
    <Box {...rest} sx={joinSx(styles.root, sx)}>
      {children}
      {loading && (
        <Box sx={styles.container}>
          <CircularProgress sx={styles.indicator} />
        </Box>
      )}
    </Box>
  );
};

const styles = createStyles({
  root: {
    position: "relative",
  },
  container: {
    backdropFilter: "blur(5px)",
    position: "absolute",
    top: 0,
    left: 0,
    minHeight: "200px",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  indicator: {

  },
});

export default LoadingContainer;
