import { createSvgIcon } from '@mui/material/utils';

const ArrowRight = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 3.25C8.20979 3.25 6.4929 3.96116 5.22703 5.22703C3.96116 6.4929 3.25 8.20979 3.25 10C3.25 10.8864 3.42459 11.7642 3.76381 12.5831C4.10303 13.4021 4.60023 14.1462 5.22703 14.773C5.85382 15.3998 6.59794 15.897 7.41689 16.2362C8.23583 16.5754 9.11358 16.75 10 16.75C10.8864 16.75 11.7642 16.5754 12.5831 16.2362C13.4021 15.897 14.1462 15.3998 14.773 14.773C15.3998 14.1462 15.897 13.4021 16.2362 12.5831C16.5754 11.7642 16.75 10.8864 16.75 10C16.75 8.20979 16.0388 6.4929 14.773 5.22703C13.5071 3.96116 11.7902 3.25 10 3.25ZM4.16637 4.16637C5.71354 2.61919 7.81196 1.75 10 1.75C12.188 1.75 14.2865 2.61919 15.8336 4.16637C17.3808 5.71354 18.25 7.81196 18.25 10C18.25 11.0834 18.0366 12.1562 17.622 13.1571C17.2074 14.1581 16.5997 15.0675 15.8336 15.8336C15.0675 16.5997 14.1581 17.2074 13.1571 17.622C12.1562 18.0366 11.0834 18.25 10 18.25C8.91659 18.25 7.8438 18.0366 6.84286 17.622C5.84192 17.2074 4.93245 16.5997 4.16637 15.8336C3.40029 15.0675 2.7926 14.1581 2.37799 13.1571C1.96339 12.1562 1.75 11.0834 1.75 10C1.75 7.81196 2.61919 5.71354 4.16637 4.16637ZM10.303 6.96967C10.5959 6.67678 11.0708 6.67678 11.3637 6.96967L13.8637 9.46967C14.1566 9.76256 14.1566 10.2374 13.8637 10.5303L11.3637 13.0303C11.0708 13.3232 10.5959 13.3232 10.303 13.0303C10.0101 12.7374 10.0101 12.2626 10.303 11.9697L11.5227 10.75H6.66667C6.25245 10.75 5.91667 10.4142 5.91667 10C5.91667 9.58579 6.25245 9.25 6.66667 9.25H11.5227L10.303 8.03033C10.0101 7.73744 10.0101 7.26256 10.303 6.96967Z" fill="currentColor"/>
    </svg>,
    'ArrowRight'
);

export default ArrowRight;

