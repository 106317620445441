import { Box, Link } from "@mui/material";
import { Star } from 'src/assets';
import { Typography } from "src/main/components";
import { createStyles, joinSx, IntlFormatter } from "src/main/utils";

interface CriterialListItemProps {
  instruction: string,
  url: string,
}

const CriterialListItem: React.FC<CriterialListItemProps> = (props) => {
  const { instruction, url } = props;
  return (
    <Box mt={1}>
      <Box sx={{ backgroundColor: "#F4F4F4", borderRadius: 1 }} py={1} px={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={joinSx(styles.itemBox)}>
            <Star sx={{ fontSize: 16 }} />
            <Box px={1}>
              <Box sx={joinSx(styles.itemBox)}>
                <Typography variant="body2" fontWeight={700} display="block" lineHeight={0.85}>
                  {instruction}
                </Typography>
              </Box>
            </Box>
          </Box>
          {url && (
            <Box sx={joinSx(styles.itemBox)} width={60}>
              <Box display="flex">
                <Link href={url} color="primary" underline="always" variant="caption" target="_blank" rel="noreferrer">
                  {IntlFormatter.getMessage("criteria.go_now", "Go Now")}
                </Link>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box >
  );
}

const styles = createStyles({
  itemBox: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    fontSize: 16,
    px: .5,
    lineHeight: 0.85,
  }
});

export default CriterialListItem;