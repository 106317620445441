import { Typography, TypographyProps } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface MessageValues {
  [key: string]: string | number;
}
interface CustomTypographyProps extends TypographyProps {
  formatId?: string;
  defaultMessage?: string;
  formatValues?: MessageValues;
}

const CustomTypography: React.FC<CustomTypographyProps> = (props) => {
  const { formatId, defaultMessage, formatValues, children, sx, ...rest } = props;

  const isStringReactNode = (node: React.ReactNode): node is string => {
    return typeof node === 'string';
  }

  const getTranslatedMessage = () => {

    if (!formatId && !defaultMessage) return children;

    if (formatId && isStringReactNode(children)) {
      return <FormattedMessage id={formatId} defaultMessage={children} values={formatValues} />;
    }
    return (
      <>
        <FormattedMessage id={formatId} defaultMessage={defaultMessage} values={formatValues} />
        {children}
      </>
    )
  }

  return (
    <Typography {...rest} sx={sx}>
      {formatId ? getTranslatedMessage() : children}
    </Typography>
  );
};

export default CustomTypography;