import { Box, capitalize } from "@mui/material";
import React from "react";
import { Telegram, Line } from 'src/assets';
import { FormattedMessage } from "react-intl";
import { Typography, GradientButton } from "src/main/components";
import { createStyles, joinSx, IntlFormatter } from "src/main/utils";
import { broadcastChannel } from "src/main/types";

interface ContactUsProps extends React.PropsWithChildren {
  broadcast: broadcastChannel | undefined
}

const handleBroadcastButton = (broadcastLink) => {
  window.open(broadcastLink, '_blank');
};

const getChannelIcon = (broadcastType) => {
  switch (broadcastType) {
    case "telegram":
      return <Telegram />
    case "line-oa":
      return <Line />
    default:
      return <Telegram />
  }
};

const getChannelLabel = (broadcastType) => {
  switch (broadcastType) {
    case "line-oa":
      return 'Line';
    default:
      return broadcastType;
  }
};

const GradientButtonComponent = ({ startIcon, onClick, children }) => (
  <GradientButton
    startIcon={startIcon}
    size="large"
    fullWidth
    sx={styles.button}
    onClick={onClick}
  >
    {children}
  </GradientButton>
);

export const BroadcastLink: React.FC<ContactUsProps> = (props) => {
  const { broadcast } = props;
  return (
    <Typography
      sx={{ cursor: "pointer" }}
      variant="caption"
      color="text.secondary"
      lineHeight="1rem"
      my={1}>
      <FormattedMessage
        id="general.join_broadcast_channel"
        defaultMessage="Join our <span></span> channel for the secret code"
        values={{
          span: chunks => (
            <Box component="span" sx={joinSx(
              styles.boardcastLink
            )}
              onClick={() => handleBroadcastButton(broadcast?.broadcastLink)}
            >
              {getChannelLabel(broadcast?.broadcastType)}
            </Box>
          ),
          cta: chunks => <>{chunks}</>,
        }}
      />
    </Typography>

  );
};

export const JoinBroadcastChannel: React.FC<ContactUsProps> = (props) => {
  const { broadcast } = props;

  return (
    <GradientButtonComponent
      startIcon={getChannelIcon(broadcast?.broadcastType)}
      onClick={() => handleBroadcastButton(broadcast?.broadcastLink)}
    >
      {IntlFormatter.getMessage("redeem.join_us_on_channel", `Join us on Telegram ${broadcast?.broadcastType}`, { channel: capitalize(getChannelLabel(broadcast?.broadcastType ?? '')) })}
    </GradientButtonComponent>

  );
};

export const GetSecretCodeHere: React.FC<ContactUsProps> = (props) => {
  const { broadcast } = props;

  return (
    <GradientButtonComponent
      startIcon={getChannelIcon(broadcast?.broadcastType)}
      onClick={() => handleBroadcastButton(broadcast?.broadcastLink)}
    >
      {IntlFormatter.getMessage("general.get_the_secret_code", "Get the secret code here")}
    </GradientButtonComponent>

  );
};

const styles = createStyles({
  boardcastLink: {
    textTransform: 'capitalize',
    color: "#5A4EE8",
    fontWeight: 700,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    color: 'primary.contrastText',
    mx: "auto",
    lineHeight: "normal"
  },
});

// export default BroadcastLink;