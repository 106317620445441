import BigNumber from "bignumber.js";

export const Defaults = {
  Theme: "theme",
  Language: "language",
  DocumentTitle: "Secret Code",

} as const;

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const TelegramChannelLink = "https://t.me/+Qu4vy2MqZJw3MjE1";

export const QueryTags = {
  Worker: [
    "coupon",
  ] as const
}

export const CampaignType = {
  Instant: "instant",
  LuckyDraw: "lucky-draw",
}

export const decimalCommaCurrencyList = ['ARS', 'BRL', 'IDR', 'VND'];