import { createSvgIcon } from '@mui/material/utils';

const Info = createSvgIcon(
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 20.9941C16.0228 20.9941 20.5 16.517 20.5 10.9941C20.5 5.47129 16.0228 0.994141 10.5 0.994141C4.97715 0.994141 0.5 5.47129 0.5 10.9941C0.5 16.517 4.97715 20.9941 10.5 20.9941ZM11.6634 9.229L10.4109 15.1104C10.323 15.5352 10.4476 15.7769 10.7918 15.7769C11.0335 15.7769 11.3997 15.689 11.6487 15.4692L11.5389 15.9893C11.18 16.4214 10.389 16.7363 9.70781 16.7363C8.82891 16.7363 8.45537 16.209 8.69707 15.0884L9.61992 10.7524C9.70049 10.3862 9.62725 10.2544 9.26103 10.1665L8.69707 10.064L8.79961 9.58789L11.6634 9.229ZM10.5 7.86914C9.80964 7.86914 9.25 7.3095 9.25 6.61914C9.25 5.92878 9.80964 5.36914 10.5 5.36914C11.1904 5.36914 11.75 5.92878 11.75 6.61914C11.75 7.3095 11.1904 7.86914 10.5 7.86914Z" fill="currentColor" />
  </svg>
  ,
  'Info'
);

export default Info;
