import { Box, TypographyProps } from "@mui/material";
import { CSSProperties } from '@mui/material/styles/createTypography';
import React from 'react';
import { Typography } from "src/main/components";
import { formatDecimalCurrency, createStyles, joinSx } from "src/main/utils";
import { JackpotBanner, JackpotPrize } from 'src/assets';

interface BorderedTypographyProps extends TypographyProps {
  backgroundGradient?: boolean,
  totalJackpotAmount: number,
  payCurrency: string | undefined,
}

const JackpotTitle: React.FC<BorderedTypographyProps> = (props) => {
  const { children, backgroundGradient, totalJackpotAmount, payCurrency, ...rest } = props;

  const containerStyle: CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  };

  const baseFontStyle: CSSProperties = {
    fontFamily: "Manrope",
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '16px',
    textAlign: 'right',
    paddingRight: '4px',
  }

  const borderEffectStyle: CSSProperties = {
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'currentColor',
    WebkitTextStroke: '3px white',
    textStroke: '3px white',
  };

  const overLapingStyle: CSSProperties = {
    position: 'absolute',
    top: '-0px',
  };

  const textShadow: CSSProperties = {
    textShadow: '1px 1px 2px #881800,0 0 1em #881800,0 0 0.2em #881800',
  };

  return (
    <>
      <Box component="img" src={JackpotBanner} sx={joinSx(styles.image)} />
      <Box sx={joinSx(styles.overlay)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: "center", pr: 2 }}>
          <Box component="img" src={JackpotPrize} sx={{ width: '20%', maxHeight: 50, pl: 1 }} />
          <div style={containerStyle}>
            <Typography formatId="lottery.jackpot_prize" style={{ ...baseFontStyle, ...borderEffectStyle, ...textShadow }} {...rest}>Jackpot Prize</Typography>
            <Typography formatId="lottery.jackpot_prize" style={{ ...baseFontStyle, ...overLapingStyle }} {...rest}>Jackpot Prize</Typography>
          </div>
          <Box sx={{ display: 'flex', color: "white" }} alignItems='center'>
            <Typography variant="h4" style={textShadow}>{formatDecimalCurrency(totalJackpotAmount, payCurrency)}</Typography>
            <Typography variant="h6" style={textShadow}>{payCurrency ?? ''}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = createStyles({
  image: {
    objectFit: "cover",
    width: "100%",
    height: 60,
    borderRadius: 1,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: 60,
    width: "100%",
  },
});

export default JackpotTitle;