import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest } from "redux-saga/effects";
import slice from "./slice";
import { ThemeMode } from "./types";

function handleAction(action: PayloadAction<ThemeMode>) {
  console.log("handleAction, new state:", action.payload);
}

function* saga() {
  yield takeLatest(slice.actions.setThemeMode.type, handleAction);
}

export default saga;
