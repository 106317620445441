import { createSvgIcon } from '@mui/material/utils';

const Twitter = createSvgIcon(
    <svg viewBox="0 0 18 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.7167 4.99992C18.075 5.29159 17.3833 5.48325 16.6667 5.57492C17.4 5.13325 17.9667 4.43325 18.2333 3.59159C17.5417 4.00825 16.775 4.29992 15.9667 4.46659C15.3083 3.74992 14.3833 3.33325 13.3333 3.33325C11.375 3.33325 9.77499 4.93325 9.77499 6.90825C9.77499 7.19159 9.80833 7.46659 9.86666 7.72492C6.89999 7.57492 4.25833 6.14992 2.49999 3.99159C2.19166 4.51659 2.01666 5.13325 2.01666 5.78325C2.01666 7.02492 2.64166 8.12492 3.60833 8.74992C3.01666 8.74992 2.46666 8.58325 1.98333 8.33325V8.35825C1.98333 10.0916 3.21666 11.5416 4.84999 11.8666C4.3256 12.0101 3.77507 12.0301 3.24166 11.9249C3.468 12.6353 3.91127 13.2569 4.50917 13.7024C5.10707 14.1478 5.82953 14.3947 6.57499 14.4083C5.31135 15.4086 3.74499 15.9493 2.13333 15.9416C1.84999 15.9416 1.56666 15.9249 1.28333 15.8916C2.86666 16.9083 4.74999 17.4999 6.76666 17.4999C13.3333 17.4999 16.9417 12.0499 16.9417 7.32492C16.9417 7.16659 16.9417 7.01659 16.9333 6.85825C17.6333 6.35825 18.2333 5.72492 18.7167 4.99992Z" fill="currentColor" />
    </svg>,
    'Twitter'
);

export default Twitter;
