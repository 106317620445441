import { createSvgIcon } from '@mui/material/utils';

const UserIcon = createSvgIcon(
    <svg viewBox="-4 -3 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.32617 12.0059C1.32617 12.0059 0.326172 12.0059 0.326172 11.0059C0.326172 10.0059 1.32617 7.00586 6.32617 7.00586C11.3262 7.00586 12.3262 10.0059 12.3262 11.0059C12.3262 12.0059 11.3262 12.0059 11.3262 12.0059H1.32617Z" fill="currentColor"/>
        <path d="M6.32617 6.00586C7.98303 6.00586 9.32617 4.66271 9.32617 3.00586C9.32617 1.34901 7.98303 0.00585938 6.32617 0.00585938C4.66932 0.00585938 3.32617 1.34901 3.32617 3.00586C3.32617 4.66271 4.66932 6.00586 6.32617 6.00586Z" fill="currentColor" />
    </svg>,
    'UserIcon'
);

export default UserIcon;


