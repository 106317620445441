export const errorMessages = {
  "max redemption reached": "Max redemption reached",
  "already redeemed": "general.username_already_redeemed",
  "the code does not available": "Domain is invalid. Please contact administrator.",
  "code expired": "Code Expired",
  "invalid username": "general.username_not_qualified",
  "invalid code": "general.invalid_secret_code",
  "email already exists": "general.email_already_exists",
  default: "Invalid code. Double-check and retry",
};

export const errorFieldMaping = {
  "invalid code": "secretCode",
  "invalid username": "username",
  default: "error",
};

export const Messages = {
  "TypeError: Failed to fetch": "Network error occurred, please check your internet connection and try again.",
}