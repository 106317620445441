import { Box, BoxProps, Grid, CardActions } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useMemo } from 'react';
import { calculateRemainingTime, IntlFormatter, checkIfFuture } from "src/main/utils";
import { ContactUs, JoinUs, CounterTitleMessage, EmailReminder, GetSecretCodeHere } from "../../components";
import { Typography } from 'src/main/components';
import { CampaignType } from 'src/main/constants';
import { socialMediaModel } from "src/main/types";

interface CountdownTimerProps extends React.PropsWithChildren {
  startTime: string | undefined;
  endTime: string | undefined;
  brandTitle: string | undefined;
  couponTitle: string;
  onCountdownComplete: () => void;
  couponInformation: any;
  socialMedia: socialMediaModel[] | undefined;
}

const StyledBox = styled(Box)<BoxProps>(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "linear-gradient(180deg, #616167 0%, #3C3C45 50.51%, #1B1B1E 50.52%, #42424A 100%)",
  fontSize: 32,
  color: "#FFFFFF",
  borderRadius: 8,
  height: 54,
  width: 58,
}));

const CountdownTimer: React.FC<CountdownTimerProps> = (props) => {
  const { startTime, onCountdownComplete, brandTitle = "Secret Code", couponTitle, couponInformation, children, socialMedia, endTime } = props;

  const isUpcoming = useMemo(() => {
    return checkIfFuture(startTime)
  }, [startTime]);

  const notExpired = useMemo(() => {
    return checkIfFuture(endTime)
  }, [endTime]);

  const reCalculateStartTime = useMemo(() => {
    if (isUpcoming)
      return startTime;
    else {
      if (couponInformation?.coupon?.type === CampaignType.LuckyDraw) {
        return endTime;
      }
    }
    // eslint-disable-next-line
  }, [startTime, endTime, isUpcoming, couponInformation]);

  const initialRemainingTime = useMemo(() => {
    return calculateRemainingTime(reCalculateStartTime)
  }, [reCalculateStartTime]);

  const [remainingTime, setRemainingTime] = useState(0);
  const formatTime = (time: number): { days: number; hours: number; minutes: number; seconds: number } => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    setRemainingTime(initialRemainingTime);
  }, [initialRemainingTime]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (remainingTime) {
      if (remainingTime > 1000) {
        timer = setInterval(() => {
          setRemainingTime(calculateRemainingTime(reCalculateStartTime));
        }, 1000);
      } else {
        onCountdownComplete(); // Call the callback function when remainingTime reaches 0
      }
      return () => {
        clearInterval(timer);
      };
    }
  }, [remainingTime, startTime, endTime, onCountdownComplete, reCalculateStartTime]);

  return (
    <div>
      <Box px={2}>
        <Box justifyContent="center" display="flex" py={1}>
          <Typography variant="h6" fontWeight={700}>{couponTitle}</Typography>
        </Box>
        {(isUpcoming || (!isUpcoming && couponInformation?.coupon?.type === CampaignType.LuckyDraw)) && (
          <Box sx={{ backgroundColor: "#A44EE81A", borderRadius: 2 }} textAlign="center" py={1} px={2}>
            <Box display="flex" justifyContent="center" pt={1} mb={2}>
              <CounterTitleMessage couponInformation={couponInformation} isUpcoming={isUpcoming} notExpired={notExpired} />
            </Box>

            <Grid container spacing={1} justifyContent="center" >
              {(formatTime(remainingTime).days >= 1) && (
                <>
                  <Grid item>
                    <StyledBox minWidth={58} px={formatTime(remainingTime).days > 99 ? 2 : 0}>{formatTime(remainingTime).days}</StyledBox>
                    <Typography formatId="countdown.days" textAlign="center" fontSize={15} fontWeight={700}>Days</Typography>
                  </Grid>
                  <Grid item>
                    <Box display="flex" alignItems="center" height={54} ><Typography fontSize={32} color="text.secondary" >:</Typography></Box>
                  </Grid>
                </>
              )}
              <Grid item>
                <StyledBox>{formatTime(remainingTime).hours}</StyledBox>
                <Typography formatId="countdown.hours" textAlign="center" fontSize={15} fontWeight={700}>{formatTime(remainingTime).hours > 1 ? IntlFormatter.getMessage("countdown.hours", "Hours") : IntlFormatter.getMessage("countdown.hour", "Hour")}</Typography>
              </Grid>

              <Grid item>
                <Box display="flex" alignItems="center" height={54} ><Typography fontSize={32} color="text.secondary" >:</Typography></Box>
              </Grid>
              <Grid item>
                <StyledBox>{formatTime(remainingTime).minutes}</StyledBox>
                <Typography textAlign="center" fontSize={15} fontWeight={700}>{formatTime(remainingTime).minutes > 1 ? IntlFormatter.getMessage("countdown.minutes", "Minutes") : IntlFormatter.getMessage("countdown.minute", "Minute")}</Typography>
              </Grid>
              {(formatTime(remainingTime).days < 1) && (
                <>
                  <Grid item>
                    <Box display="flex" alignItems="center" height={54} ><Typography fontSize={32} color="text.secondary" >:</Typography></Box>
                  </Grid>
                  <Grid item justifyContent="center">
                    <StyledBox>{formatTime(remainingTime).seconds}</StyledBox>
                    <Typography textAlign="center" fontSize={15} fontWeight={700} >{formatTime(remainingTime).seconds > 1 ? IntlFormatter.getMessage("countdown.seconds", "Seconds") : IntlFormatter.getMessage("countdown.second", "Second")}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}

        <Box display="flex" py={.5} />
        {children}
      </Box>

      {isUpcoming && (
        <>
          <ContactUs startTime={startTime} brandTitle={brandTitle} socialMedia={socialMedia} />
          <EmailReminder />
          <CardActions sx={{ mb: 2 }}>
            <JoinUs>
              <GetSecretCodeHere broadcast={couponInformation?.page} />
            </JoinUs>
          </CardActions>
        </>
      )}

    </div >
  );
};

export default CountdownTimer;