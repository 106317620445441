import { createSvgIcon } from '@mui/material/utils';

const Fire = createSvgIcon(
    <svg viewBox="0 0 12 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 16C9.31371 16 12 14 12 10.5C12 9 11.5 6.5 9.5 4.5C9.75 6 8.25 6.5 8.25 6.5C9 4 7 0.5 4 0C4.35714 2 4.5 4 2 6C0.75 7 0 8.72893 0 10.5C0 14 2.68629 16 6 16ZM6 15C4.34315 15 3 14 3 12.25C3 11.5 3.25 10.25 4.25 9.25C4.125 10 5 10.5 5 10.5C4.625 9.25 5.5 7.25 7 7C6.82143 8 6.75 9 8 10C8.625 10.5 9 11.3645 9 12.25C9 14 7.65685 15 6 15Z" fill="currentColor" />
    </svg>,
    'Fire'
);

export default Fire;
