import { Dialog, DialogContent, List, ListItem, ListItemButton, ListItemText, Divider, Box, Button } from "@mui/material";
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/main/store';
import { ChevronRight } from "src/assets";
import { Typography } from 'src/main/components';
import preference from 'src/main/store/preference';
import { LANGUAGE_OPTIONS, IntlFormatter, browserLanguageSetting, messages, getLanguageLabel } from "src/main/utils";

interface LanguageSwithcerProps {
  defaultValue: string | undefined;
}

const LanguageSwithcer: React.FC<LanguageSwithcerProps> = (props) => {
  const { defaultValue } = props;
  const dispatch = useDispatch();
  const language = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;
  const [languageDialogFlag, setLanguageDialogFlag] = useState<boolean>(false);
  const [defaultLang, setDefaultLang] = useState<string>();
  const languageLabel = getLanguageLabel(language);

  const handleUpdateLanguage = (value: string) => {
    setLanguageDialogFlag(false);
    dispatch(preference.slice.actions.setLanguage(value))
  };

  const handleLanguage = () => {
    setLanguageDialogFlag(!languageDialogFlag);
  }

  const setDefaultLanguage = useCallback(() => {
    if (messages[defaultValue ?? language]) {
      if (defaultValue === defaultLang) return;

      setDefaultLang(defaultValue);
      dispatch(preference.slice.actions.setLanguage(defaultValue ?? language));
    }
  }, [defaultValue, dispatch, defaultLang, language])

  useEffect(() => {
    setDefaultLanguage();
  }, [setDefaultLanguage]);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button
          onClick={handleLanguage}
          sx={{ color: "white", fontWeight: "400" }}
          startIcon={languageLabel.icon}
          endIcon={<ChevronRight sx={{ "&.MuiSvgIcon-root": { fontSize: "16px" } }} />}
        >
          <Typography variant="subtitle2">{languageLabel.label}</Typography>
        </Button>
      </Box>
      <Dialog fullWidth open={languageDialogFlag}>
        <DialogContent>
          <Typography textAlign="center" formatId="dialog.choose_language" variant="h6">Choose your language</Typography>
          <List sx={{ pt: 2 }}>
            {LANGUAGE_OPTIONS.map((opt, index) => (

              <ListItem disableGutters sx={{ py: 0 }} key={index}>
                <Box flexDirection="column" width="100%" >
                  <Box display="flex" justifyContent="space-between">
                    <ListItemButton sx={{ px: 1 }} onClick={() => handleUpdateLanguage(opt.value)} key={index}>
                      {opt.icon}
                      <ListItemText
                        sx={{ px: 1 }}
                        primary={
                          (<Typography variant="body2" fontWeight={(opt.value === language) ? "700" : "inherit"}>{opt.label} {!!(opt.value === language) && IntlFormatter.getMessage("languageSetting.current", "(current)")}</Typography>)
                        }
                      />
                      <ChevronRight sx={{
                        color: "#BDC2C9",
                        fontSize: "14px"
                      }} />
                    </ListItemButton>
                  </Box>
                  {!!(index !== LANGUAGE_OPTIONS.length - 1) && <Divider />}
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LanguageSwithcer;