import { CardMedia } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

interface ImageData {
  url: string | undefined;
  mimeType: string | undefined;
}

export interface BannerProps {
  images: ImageData[];
}

const Banner: React.FC<BannerProps> = (props) => {
  const { images } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: false
  };

  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <CardMedia
          key={index}
          component="img"
          sx={{ objectFit: "contain", backgroundColor: "#F7F7F7" }}
          image={item.url}
        />
      ))}
    </Slider>
  );
};

export default Banner;