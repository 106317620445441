import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, IconButton, useTheme } from "@mui/material";
import { ArrowRight, GiftBanner, IconX } from "src/assets";
import { BorderedTypography, Typography } from "src/main/components";
import { ContactUs } from "../../components";
import { IntlFormatter, browserLanguageSetting } from "src/main/utils";
import { useSelector } from 'react-redux';
import { RootState } from 'src/main/store';
import { socialMediaModel } from "src/main/types";

interface ConfirmDialogProps extends Omit<DialogProps, "open"> {
  creditsWon: string;
  brandName: string;
  username: string;
  brandHandle: string;
  secretCode: string;
  openFlag?: boolean;
  socialMedia: socialMediaModel[] | undefined;
}
const SuccessDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { creditsWon, brandName, brandHandle, secretCode, username, openFlag = false, onClose, socialMedia, ...dialogProps } = props;
  const theme = useTheme();
  const language = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={openFlag}
      onClose={_onClose}
      sx={{ ".MuiDialog-paper": { overflow: "visible" } }
      }>
      <Box
        sx={{
          zIndex: 10000,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={-15}
      >
        <Box
          component="img"
          src={GiftBanner}
        />
        <Box sx={{ position: "absolute" }} textAlign="center">
          <BorderedTypography fontSize={(language === "th") ? "38px" : "28px"} lineHeight="28px">{IntlFormatter.getMessage("dialog.you_won", "YOU WON")}</BorderedTypography>
          <BorderedTypography pt={1} fontSize="52px" lineHeight="40px" backgroundGradient={true}>+{creditsWon}</BorderedTypography>
          <BorderedTypography pt={1} fontSize={(language === "th") ? "34px" : "24px"} lineHeight="28px" color="text.primary">{IntlFormatter.getMessage("dialog.credits", "Credits")}</BorderedTypography>
        </Box>

      </Box>
      <Box sx={{
        position: "absolute",
        zIndex: 10000,
        right: -10, top: -10,
      }}>
        <IconButton
          onClick={() => _onClose({}, "escapeKeyDown")}
          sx={
            {
              backgroundColor: "white", borderRadius: 10, boxShadow: 8, p: .5,
              ":hover": {
                backgroundColor: "white"
              }
            }
          }>
          <IconX fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent sx={{ marginTop: -5 }}>
        <Typography formatId="dialog.success" textAlign="center" variant="h5">
          Success!
        </Typography>
        <Typography
          color="text.secondary"
          textAlign="center"
          variant="body2"
          formatId="dialog.credit_will_be_added"
          formatValues={{ creditsWon: creditsWon, brandName: brandName, username: username }}
          defaultMessage={`${creditsWon} credits will be added to your ${brandName} account ${username} in 24 hours`}
        >
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 4 }}>
        <Button
          href={brandHandle}
          startIcon={<ArrowRight />}
          variant="contained"
          sx={{ background: theme.palette.primary.gradient, fontWeight: 700, boxShadow: 10 }}
        >
          {IntlFormatter.getMessage("dialog.go_to_site", `Go to ${brandName} site`, { brandName: brandName })}
        </Button>
      </DialogActions>
      <Box pb={4} >
        <ContactUs secretCode={secretCode} socialMedia={socialMedia} />
      </Box>
    </Dialog>
  );
}

export default SuccessDialog;
