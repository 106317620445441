import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ThemeMode } from './types';
import { Defaults } from 'src/main/constants';

interface PreferenceState {
  theme: ThemeMode;
  language: string | null;
}

const getPersisTheme = () => {
  try {
    const savedTheme = localStorage.getItem(Defaults.Theme);
    if (savedTheme === "dark" || savedTheme === "light") return savedTheme;
  } catch (error) {
  }
  return "light";
}
const getPersisLanguage = () => {
  try {
    const savedLanguage = localStorage.getItem(Defaults.Language);
    return savedLanguage
  } catch (error) {
  }
  return "en";
}
const initialState: PreferenceState = {
  theme: getPersisTheme(),
  language: getPersisLanguage(),
};

const preferenceSlice = createSlice({
  name: '@preference',
  initialState,
  reducers: {
    setThemeMode(state, action: PayloadAction<ThemeMode>) {
      state.theme = action.payload;
      localStorage.setItem(Defaults.Theme, state.theme);
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem(Defaults.Language, state.language);
    },
  },
})

export default preferenceSlice;
