import { Dialog, DialogContent, DialogProps, List, ListItem, ListItemButton, ListItemText, Divider, Box } from "@mui/material";
import React from "react";
import { useDispatch } from 'react-redux';
import { ChevronRight } from "src/assets";
import { Typography } from 'src/main/components';
import preference from 'src/main/store/preference';
import { LANGUAGE_OPTIONS, IntlFormatter } from "src/main/utils";

export interface SimpleDialogProps extends Omit<DialogProps, "open"> {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const SelectLanguage: React.FC<SimpleDialogProps> = (props) => {
  const dispatch = useDispatch();
  const { open, onClose, selectedValue, ...dialogProps } = props;

  const handleUpdateLanguage = (value: string) => {
    onClose(value);
    dispatch(preference.slice.actions.setLanguage(value))
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog fullWidth {...dialogProps} onClose={handleClose} open={open}>

      <DialogContent>
        <Typography textAlign="center" formatId="dialog.choose_language" variant="h6">Choose your language</Typography>
        <List sx={{ pt: 2 }}>
          {LANGUAGE_OPTIONS.map((opt, index) => (

            <ListItem disableGutters sx={{ py: 0 }} key={index}>
              <Box flexDirection="column" width="100%" >
                <Box display="flex" justifyContent="space-between">
                  <ListItemButton sx={{ px: 1 }} onClick={() => handleUpdateLanguage(opt.value)} key={index}>
                    {opt.icon}
                    <ListItemText
                      sx={{ px: 1 }}
                      primary={
                        (<Typography variant="body2" fontWeight={(opt.value === selectedValue) ? "700" : "inherit"}>{opt.label} {!!(opt.value === selectedValue) && IntlFormatter.getMessage("languageSetting.current", "(current)")}</Typography>)
                      }
                    />
                    <ChevronRight sx={{
                      color: "#BDC2C9",
                      fontSize: "14px"
                    }} />
                  </ListItemButton>
                </Box>
                {!!(index !== LANGUAGE_OPTIONS.length - 1) && <Divider />}
              </Box>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SelectLanguage;