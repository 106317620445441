import React from "react";
import { FireIcon } from 'src/assets';
import { Typography } from "src/main/components";
import { CampaignType } from 'src/main/constants';
import { IntlFormatter } from "src/main/utils";

interface CampaignTitleMessageProps extends React.PropsWithChildren {
  redeemStatus?: boolean;
  starTime?: string;
  isUpcoming?: boolean;
  notExpired?: boolean;
  couponInformation?: any;
}

export const FormTitleMessage: React.FC<CampaignTitleMessageProps> = (props) => {
  const { redeemStatus, couponInformation, isUpcoming } = props;
  return (
    <>
      <FireIcon fontSize="small" color="error" />
      <Typography variant="subtitle2" fontWeight={700} color="error">
        {!isUpcoming && (<>
          {IntlFormatter.getMessage("general.hurry", "Hurry!")} {(!redeemStatus) ? <>
            {couponInformation?.coupon?.type === CampaignType.Instant && (IntlFormatter.getMessage("general.only_left", `Only ${couponInformation?.remainingRedeem} left!`, { remain: couponInformation?.remainingRedeem }))}
            {couponInformation?.coupon?.type === CampaignType.LuckyDraw && (IntlFormatter.getMessage("general.in_the_bag", `${couponInformation?.totalRedeemed} entries in the bag`, { count: couponInformation?.totalRedeemed }))}
          </> : couponInformation?.coupon?.type === CampaignType.LuckyDraw ? (IntlFormatter.getMessage("general.be_the_first_to_enter", "Be the first to enter!")) : ''}
        </>)}
      </Typography>
    </>
  );
};

export const CounterTitleMessage: React.FC<CampaignTitleMessageProps> = (props) => {
  const { couponInformation, isUpcoming, notExpired } = props;
  return (
    <>
      <FireIcon fontSize="small" color="error" />
      {isUpcoming && <Typography formatId="countdown.campaign_start" variant="subtitle2" fontWeight={700} color="error">
        Campaign Starts in...
      </Typography>}

      {(!isUpcoming && notExpired && couponInformation?.coupon?.type === CampaignType.LuckyDraw) && (<Typography formatId="countdown.lucky_draw_start" variant="subtitle2" fontWeight={700} color="error">
        Lucky Draw Announcement in...
      </Typography>)}

    </>
  );
};