import { createSvgIcon } from '@mui/material/utils';

const Telegram = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99996 1.66675C5.39996 1.66675 1.66663 5.40008 1.66663 10.0001C1.66663 14.6001 5.39996 18.3334 9.99996 18.3334C14.6 18.3334 18.3333 14.6001 18.3333 10.0001C18.3333 5.40008 14.6 1.66675 9.99996 1.66675ZM13.8666 7.33341C13.7416 8.65008 13.2 11.8501 12.925 13.3251C12.8083 13.9501 12.575 14.1584 12.3583 14.1834C11.875 14.2251 11.5083 13.8667 11.0416 13.5584C10.3083 13.0751 9.89163 12.7751 9.18329 12.3084C8.35829 11.7667 8.89163 11.4667 9.36663 10.9834C9.49163 10.8584 11.625 8.91675 11.6666 8.74175C11.6724 8.71524 11.6716 8.68772 11.6644 8.66158C11.6571 8.63544 11.6436 8.61147 11.625 8.59175C11.575 8.55008 11.5083 8.56675 11.45 8.57508C11.375 8.59175 10.2083 9.36675 7.93329 10.9001C7.59996 11.1251 7.29996 11.2417 7.03329 11.2334C6.73329 11.2251 6.16663 11.0667 5.74163 10.9251C5.21663 10.7584 4.80829 10.6667 4.84163 10.3751C4.85829 10.2251 5.06663 10.0751 5.45829 9.91675C7.89163 8.85841 9.50829 8.15841 10.3166 7.82508C12.6333 6.85842 13.1083 6.69175 13.425 6.69175C13.4916 6.69175 13.65 6.70841 13.75 6.79175C13.8333 6.85841 13.8583 6.95008 13.8666 7.01675C13.8583 7.06675 13.875 7.21675 13.8666 7.33341Z" fill="currentColor" />
    </svg>,
    'Telegram'
);

export default Telegram;
