import { SxProps, Theme } from "@mui/material";

export const joinSx = (...sxArgs: (SxProps<Theme> | undefined)[]) => {
  const result = {};
  for (const sx of sxArgs)
    if (sx)
      Object.assign(result, sx);
  return result;
}

export const createStyles = <T extends { [index: string]: SxProps }>(args: T): { [key in keyof T]: SxProps } => {
  return args;
}

export const commonStyles = createStyles({
  cardContent: {
    p: 2,
    "&:last-child": {
      pb: 1,
    }
  },

  cardHeader: {
    py: 2,
  },

  authContainer: {
    paddingTop: {
      xs: 8,
      sm: 14,
    },
  },

  dialogCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    mt: 1,
    mr: 1,
  },

  reference: {
    opacity: .5,
    fontFamily: "'Roboto Mono', monospace",
    fontSize: "10px",
  },

  borderlessChip: {
    boxShadow: '0px 0px 2px rgba(31, 36, 60, 0.2)',
    border: 0,
  }
});
