import React from "react";
import { TextField, TextFieldProps, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

type SearchbarProps = TextFieldProps & {
  onSearchChange: (search: string) => void;
}

const SearchbarComponent: React.FC<SearchbarProps> = (props) => {
  const { label = "Search", placeholder = "Search...", onSearchChange } = props;
  let timer: number;

  const debounce = (search: string) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      onSearchChange(search)
    }, 500) as unknown as number;
  }

  return (
    <TextField
      fullWidth
      label={label}
      name="search"
      onChange={(event) => debounce(event.target.value)}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        )
      }}
      placeholder={placeholder}
    />
  );
};

export default SearchbarComponent;
