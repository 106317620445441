import { Box } from "@mui/material";
import React from "react";
import { BackButton } from "src/main/components";
import { IntlFormatter, Paths } from "src/main/utils";

interface JoinUsProps extends React.PropsWithChildren {
}

const ContactUs: React.FC<JoinUsProps> = (props) => {
  const { children } = props;
  return (
    <Box display="flex" textAlign="center" width={1} flexDirection='column'>
      {children}
      <BackButton url={Paths.Portal.CampaignList} label={IntlFormatter.getMessage("general.view_all_campaigns", "View All Campaigns")} />
    </Box>
  );
};

export default ContactUs;