import { createSvgIcon } from '@mui/material/utils';

const Subtract = createSvgIcon(
  <svg viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 1.99414C0.5 1.16571 1.17157 0.494141 2 0.494141H15C15.8284 0.494141 16.5 1.16571 16.5 1.99414V3.49414C16.5 3.77028 16.2761 3.99414 16 3.99414C15.1716 3.99414 14.5 4.66571 14.5 5.49414C14.5 6.32257 15.1716 6.99414 16 6.99414C16.2761 6.99414 16.5 7.218 16.5 7.49414V8.99414C16.5 9.82257 15.8284 10.4941 15 10.4941H2C1.17157 10.4941 0.5 9.82257 0.5 8.99414V7.49414C0.5 7.218 0.723858 6.99414 1 6.99414C1.82843 6.99414 2.5 6.32257 2.5 5.49414C2.5 4.66571 1.82843 3.99414 1 3.99414C0.723858 3.99414 0.5 3.77028 0.5 3.49414V1.99414ZM4.5 0.994141V1.99414H5.5V0.994141H4.5ZM5.5 3.99414V2.99414H4.5V3.99414H5.5ZM12.5 3.99414V2.99414H11.5V3.99414H12.5ZM11.5 1.99414H12.5V0.994141H11.5V1.99414ZM5.5 4.99414H4.5V5.99414H5.5V4.99414ZM12.5 5.99414V4.99414H11.5V5.99414H12.5ZM5.5 6.99414H4.5V7.99414H5.5V6.99414ZM12.5 7.99414V6.99414H11.5V7.99414H12.5ZM4.5 8.99414V9.99414H5.5V8.99414H4.5ZM11.5 9.99414H12.5V8.99414H11.5V9.99414Z" fill="white" />
  </svg>,
  'Coin'
);

export default Subtract;
