import { Button, ButtonProps, useTheme } from "@mui/material";
import React from "react";
import { joinSx } from "src/main/utils";

interface GradientButtonProps extends ButtonProps {
  loading?: boolean;
}

const GradientButton: React.FC<GradientButtonProps> = (props) => {
  const theme = useTheme();
  const { disabled = false, loading = false, fullWidth = true, size = "large", onClick = () => { }, variant = "contained", children, sx, ...rest } = props;
  return (
    <Button
      onClick={onClick}
      disabled={loading || disabled}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      sx={joinSx({ background: theme.palette.primary.gradient }, sx)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GradientButton;