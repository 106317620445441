import { useIntl } from 'react-intl';

interface MessageValues {
  [key: string]: string | number;
}

class IntlFormatter {
  static getMessage = (id: string, defaultMessage: string, value?: MessageValues) => {
    const intl = useIntl();
    return intl.formatMessage({ id: id, defaultMessage: defaultMessage },
      { ...value }
    );
  }
}

export default IntlFormatter;


