import { Box, Paper } from '@mui/material';
import React from 'react';
import { CriteriaBanner, Star } from 'src/assets';
import { Typography } from 'src/main/components';
import { CouponCriteriaModel } from "src/main/types";
import { createStyles, joinSx } from "src/main/utils";
import { CriterialListItem } from "./components";

interface CriteriaProps {
  criterias: CouponCriteriaModel[]
  campaignEnded: boolean
}

const CriteriaList: React.FC<CriteriaProps> = (props) => {

  const { criterias, campaignEnded } = props;
  const MAX_STAR_TO_SHOW = 3;
  const NO_OF_QUEST = criterias.length;


  type StarRatingProps = {
    numberOfStars: number; // Number of stars to render
  };

  const StarRating: React.FC<StarRatingProps> = ({ numberOfStars }) => {
    const limitedNumberOfStars = Math.min(numberOfStars, MAX_STAR_TO_SHOW);
    const stars = Array.from({ length: limitedNumberOfStars }, (_, index) => <Star key={index} sx={{ fontSize: { xs: 25, sm: 40 }, px: .5 }} />);
    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={joinSx(styles.customCaption)}> {stars} {numberOfStars > MAX_STAR_TO_SHOW ? '+' : ''}</Box>
        </Box>
      </>
    );
  };


  return (
    <>
      {(!!NO_OF_QUEST) && (
        <Box pt={1} sx={{ display: 'flex', justifyContent: 'center' }} >
          <Paper elevation={3} sx={{ width: "100%", p: 1 }} >
            <Box sx={{ width: "100%", position: "relative" }}>
              <Box component="img" src={CriteriaBanner} sx={joinSx(styles.image)} />
              <Box sx={joinSx(styles.overlay)}>
                <Typography
                  formatId={`${campaignEnded ? 'criteria.campaign_quests' : 'criteria.complete_quests_to_join_campaign'}`}
                  defaultMessage={`${campaignEnded ? 'Campaign Quests' : ' Complete quests to join campaign'}`}
                  formatValues={{ number: NO_OF_QUEST }}
                  variant="h6"
                  textAlign="center"
                  display="block" lineHeight={1}
                  sx={joinSx(styles.customH6)}>
                </Typography>
                <Box mx={5} mr={0} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center', height: '100%', marginTop: 4 }}>
                  <Box>
                    <StarRating numberOfStars={NO_OF_QUEST} />
                    <Typography
                      formatId={`${campaignEnded ? 'criteria.quest_completed' : 'criteria.no_quest_required'}`}
                      defaultMessage={`${NO_OF_QUEST} ${campaignEnded ? ' Quests Completed' : ' Quests Required'}`}
                      formatValues={{ count: NO_OF_QUEST }}
                      fontWeight={700}
                      variant="body2"
                      sx={joinSx(styles.customBody2)}></Typography>
                  </Box>
                  <Box sx={{ width: { xs: "35%", sm: "50%" } }}></Box>
                </Box>
              </Box>
              <Box mt={-1}>
                {criterias.map((criteria, index) => (
                  <CriterialListItem key={index} instruction={criteria.instruction} url={criteria.url} />
                ))}
              </Box>
            </Box>
          </Paper >
        </Box >
      )}
    </>
  );
};

const styles = createStyles({
  image: {
    width: "100%",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },

  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    height: {
      xs: 120,
      sm: 160,
    },
    '@media (max-width:400px)': {
      height: 80,
    },
    '@media (max-width:320px)': {
      height: 60,
    },
    width: "100%",
  },
  customH6: {
    width: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    mx: 'auto',
    color: "white",
    fontSize: '0.75rem',
    '@media (min-width:375px)': {
      fontSize: '1rem',
    },
    marginTop: {
      xs: 1,
      sm: 2,
    }
  },
  customBody2: {
    color: "white",
    fontSize: '0.625rem',
    '@media (min-width:375px)': {
      fontSize: '0.75rem',
    },
  },
  customCaption: {
    display: "flex",
    color: "white",
    alignItems: "center",
    fontSize: {
      xs: 20,
      sm: 50
    }
  },
});

export default CriteriaList;
