import { createSvgIcon } from '@mui/material/utils';

const Messenger = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99996 1.66675C5.29996 1.66675 1.66663 5.10841 1.66663 9.75008C1.66663 12.1751 2.65829 14.2834 4.28329 15.7251C4.41663 15.8334 4.49996 16.0167 4.50829 16.2001L4.54996 17.6834C4.58329 18.1584 5.05829 18.4667 5.49163 18.2751L7.14163 17.5501C7.28329 17.5001 7.44163 17.4751 7.58329 17.5001C8.33329 17.7251 9.16663 17.8334 9.99996 17.8334C14.7 17.8334 18.3333 14.3917 18.3333 9.75008C18.3333 5.10841 14.7 1.66675 9.99996 1.66675ZM15 7.88342L12.5583 11.7751C12.1666 12.3834 11.3333 12.5417 10.75 12.0834L8.79996 10.6417C8.71341 10.5768 8.60814 10.5417 8.49996 10.5417C8.39177 10.5417 8.28651 10.5768 8.19996 10.6417L5.56663 12.6417C5.21663 12.9167 4.75829 12.5001 4.99996 12.1167L7.44163 8.22508C7.83329 7.61675 8.66663 7.45842 9.24996 7.89175L11.2 9.35841C11.2865 9.42333 11.3918 9.45842 11.5 9.45842C11.6081 9.45842 11.7134 9.42333 11.8 9.35841L14.4333 7.35842C14.7833 7.08342 15.2416 7.50008 15 7.88342Z" fill="currentColor" />
    </svg>,
    'Messenger'
);

export default Messenger;
