import { Box, Button, Card, CardContent, Tab, Tabs, Link } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { SelectLanguage } from "../components";
import { browserLanguageSetting, getLanguageLabel, messages, createStyles, Meta, getDomain, joinSx } from "src/main/utils";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/main/store';
import { LandingLayout, ListingComp, Typography } from "src/main/components";
import { ChevronRight, HeroImg, LightningActiveIcon, LightningIcon, MoonIcon, MoonActiveIcon, StopWatchIcon, StopWatchActiveIcon, WinHqLogoImg, Sorte7LogoImg, XogoLogoImg } from "src/assets";
import Coupon from "../components/Coupon/Coupon";
import { publicApi } from "src/main/api";
import preference from "src/main/store/preference";
import { GetCouponPageResponse } from "src/main/api/public/Coupon";

interface HandleParams {
  domain: string;
}
const styles = createStyles({
  activeTab: {
    background: "linear-gradient(85.28deg, #6A0FDE 0%, #579AFF 99.55%)",
    "WebkitBackgroundClip": "text",
    "WebkitTextFillColor": "transparent",
  },
  button: {
    color: 'primary.contrastText',
    mx: "auto",
    py: 0
  },
  cardBoxShadow: {
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;"
  }
})
const tabs = [
  {
    value: "ongoing", label: "Ongoing", subLabel: "Happening Now!",
    icon: [<LightningIcon />, <LightningActiveIcon />]
  },
  {
    value: "upcoming", label: "Upcoming", subLabel: "What’s Next",
    icon: [<StopWatchIcon />, <StopWatchActiveIcon />]
  },
  {
    value: "ended", label: "Ended", subLabel: "Completed",
    icon: [<MoonIcon />, <MoonActiveIcon />]
  },
]
interface CampaignListProps extends React.PropsWithChildren {

}
const CampaignList: React.FC<CampaignListProps> = (props) => {

  const [languageDialogFlag, setLanguageDialogFlag] = useState<boolean>(false);
  const language = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;
  const languageLabel = getLanguageLabel(language);
  const [tabVal, setTabVal] = useState(tabs[0].value);
  const [meta, setMeta] = useState<Meta>({ count: 0, limit: 10, offset: 0 });
  const dispatch = useDispatch();

  const domain = getDomain();

  const campaignQueryResult = publicApi.useGetCampaignListQuery({ ...meta, ...{ domain: domain, type: tabVal } });
  const coupons = useMemo(() => campaignQueryResult.data ?? undefined, [campaignQueryResult]);

  const couponPageMeta: HandleParams = { domain }
  const couponPageApiResult = publicApi.useGetCouponPageQuery({ couponPageMeta });
  const couponPage: GetCouponPageResponse | undefined = useMemo(() => couponPageApiResult.data ?? undefined, [couponPageApiResult.data]);

  const persistMeta = useMemo(() => ({ ...meta, ...campaignQueryResult.data?.result?.meta }), [campaignQueryResult.data, meta]);

  const [defaultLang, setDefaultLang] = useState<string>();
  const setDefaultLanguage = useCallback(() => {
    if (messages[couponPage?.page?.defaultLanguage ?? language]) {
      if (couponPage?.page?.defaultLanguage === defaultLang) return;

      setDefaultLang(couponPage?.page?.defaultLanguage);
      dispatch(preference.slice.actions.setLanguage(couponPage?.page?.defaultLanguage ?? language));
    }
  }, [couponPage, dispatch, defaultLang, language])

  useEffect(() => {
    setDefaultLanguage();
  }, [setDefaultLanguage]);

  const translatedTabs = tabs.map(tab => ({
    ...tab,
    icon: (tab.value === tabVal) ? tab.icon[1] : tab.icon[0],
    label: <Box>
      <Typography
        fontSize="15px"
        sx={joinSx((tab.value === tabVal) ? styles.activeTab : { color: "#1B1B1E" }, { fontWeight: "700" })}
        formatId={`campaign.tab_${tab.value}`}
      >
        {tab.label}
      </Typography>
      <Typography fontSize="12px" lineHeight="16px" color="text.secondary" formatId={`campaign.tab_${tab.value}_sub`}>{tab.subLabel}</Typography>
    </Box>,
    value: tab.value,
  }));

  const handleLanguage = () => {
    setLanguageDialogFlag(!languageDialogFlag);
  };

  const handleClose = (value: string) => {
    setLanguageDialogFlag(false);
  };

  const handleTabChange = useCallback((curTab: string) => {
    const newMeta = { ...persistMeta };

    setTabVal(curTab);
    newMeta.type = curTab;
    setMeta({ ...newMeta, offset: 0 });
  }, [setTabVal, persistMeta]);

  const handleCountdownComplete = () => {
    if (tabVal === "upcoming") {
      //handleTabChange(tabVal);
    }
  };

  const openBrandHandler = () => {
    if (couponPage?.page?.brandHandle) {
      window.open(couponPage.page.brandHandle, '_blank');
    }
  };

  const handleLogo = () => {
    switch (domain) {
      case "whqprize.com":
        return WinHqLogoImg;
      case "s7gratis.com":
        return Sorte7LogoImg;
      case "xogowin.com":
        return XogoLogoImg;
    }
  }

  return (
    <LandingLayout.Container>
      <LandingLayout.Content>
        <SelectLanguage
          selectedValue={language}
          open={languageDialogFlag}
          onClose={handleClose}
        />
        <Box pb={2}>
          <Box display="flex" justifyContent="space-between">
            <Box
              component="img"
              sx={{ cursor: "pointer" }}
              src={couponPage?.page?.brandLogo?.url ?? handleLogo()}
              alt=""
              onClick={openBrandHandler}
              maxWidth={{ xs: "150px", md: "250px" }}
            />
            <Button
              onClick={handleLanguage}
              sx={{ color: "white", fontWeight: "400" }}
              endIcon={<ChevronRight sx={{ "&.MuiSvgIcon-root": { fontSize: "16px" } }} />}
            >
              <Typography variant="body2">{languageLabel.label}</Typography>
            </Button>
          </Box>
        </Box>
        <Box position="relative" minHeight="189px" display="flex" alignItems="end">
          <Box
            position="absolute"
            bottom="-1px"
            zIndex="0"
            width="100%"
            height="16px"
            borderRadius="8px 8px 0px 0px"
            sx={{ backgroundColor: "#F8F9FC", }}
          ></Box>
          <Box position="absolute" zIndex="1" right="0">
            <img src={HeroImg} alt="" width="200px" height="180px" />
          </Box>
          <Box position="relative" display="flex" alignItems="space-between">
            <Box width={{
              xs: "50%", md: "55%"
            }} pt={1} pb="30px">
              <Typography lineHeight={1.2} whiteSpace="pre-line" color="white" gutterBottom variant="h4" formatId="campaign.who_want_free_credit">{`Who wants\nfree credits?`}</Typography>
              <Typography variant="body2" color="white" formatId="campaign.shhh">
                Shhh🤫... Get free credits with the Secret Code. Limited availability, enter below now!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Card sx={{ borderRadius: "0px 0px 8px 8px" }} >
          <CardContent sx={{ p: 0 }}>
            <Tabs
              value={tabVal}
              variant="fullWidth"
              onChange={(ev, value) => { handleTabChange(value) }}
              aria-label=""
              sx={{
                px: 1,
                backgroundColor: "#F8F9FC",
                "& .MuiTabs-fixed": { pb: 1 }
              }}
            >
              {translatedTabs.map((tab, index) => (
                <Tab
                  key={index}
                  icon={tab.icon}
                  label={tab.label}
                  value={tab.value}
                  sx={{ py: 0, "& .MuiTab-iconWrapper": { m: 0 } }}
                />
              ))}
            </Tabs>
            <Box pt={1} px={1}>
              <ListingComp
                loading={campaignQueryResult.isLoading}
                title=""
                updateList={(newMeta) => { setMeta(newMeta) }}
                meta={persistMeta}
                mode="tasker"
                sx={{ minHeight: "200px" }}
              >
                {coupons?.result?.entries?.map((coupon, index) => (
                  <Link key={index} href={coupon.handle} underline="none">
                    <Coupon couponDetails={coupon} status={tabVal} myKey={index} onCountdownComplete={handleCountdownComplete} />
                  </Link>
                ))}
              </ListingComp>
            </Box>
          </CardContent>
        </Card>
        <Box height="40px"></Box>
      </LandingLayout.Content>
    </LandingLayout.Container>);
}


export default CampaignList;