import { createSvgIcon } from '@mui/material/utils';

const Faceboox = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3333 10.0001C18.3333 5.40008 14.6 1.66675 9.99996 1.66675C5.39996 1.66675 1.66663 5.40008 1.66663 10.0001C1.66663 14.0334 4.53329 17.3917 8.33329 18.1667V12.5001H6.66663V10.0001H8.33329V7.91675C8.33329 6.30841 9.64163 5.00008 11.25 5.00008H13.3333V7.50008H11.6666C11.2083 7.50008 10.8333 7.87508 10.8333 8.33341V10.0001H13.3333V12.5001H10.8333V18.2917C15.0416 17.8751 18.3333 14.3251 18.3333 10.0001Z" fill="currentColor" />
    </svg>,
    'Faceboox'
);

export default Faceboox;
