import { createSvgIcon } from '@mui/material/utils';

const Coin = createSvgIcon(
    <svg viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 9.511C6.0763 10.4645 6.82909 11.2078 8.1821 11.2958V12H8.7823V11.291C10.1811 11.1932 11 10.445 11 9.35941C11 8.37164 10.3744 7.86308 9.25534 7.59902L8.7823 7.48655V5.56968C9.3825 5.63814 9.76399 5.96577 9.85554 6.42054H10.9084C10.8321 5.50122 10.0437 4.7824 8.7823 4.70416V4H8.1821V4.71883C6.98677 4.83619 6.17294 5.55501 6.17294 6.57213C6.17294 7.47188 6.77823 8.04401 7.78535 8.27873L8.1821 8.37653V10.4108C7.56663 10.3178 7.15972 9.98044 7.06816 9.511H6ZM8.17701 7.34474C7.58698 7.20782 7.26653 6.9291 7.26653 6.50856C7.26653 6.03912 7.61241 5.68704 8.1821 5.58435V7.34474H8.17701ZM8.86877 8.5379C9.58596 8.70416 9.91658 8.97311 9.91658 9.44743C9.91658 9.99022 9.50458 10.3619 8.7823 10.4303V8.51834L8.86877 8.5379Z" fill="currentColor" />
        <path d="M8.5 15C4.63401 15 1.5 11.866 1.5 8C1.5 4.13401 4.63401 1 8.5 1C12.366 1 15.5 4.13401 15.5 8C15.5 11.866 12.366 15 8.5 15ZM8.5 16C12.9183 16 16.5 12.4183 16.5 8C16.5 3.58172 12.9183 0 8.5 0C4.08172 0 0.5 3.58172 0.5 8C0.5 12.4183 4.08172 16 8.5 16Z" fill="currentColor" />
        <path d="M8.5 13.5C5.46243 13.5 3 11.0376 3 8C3 4.96243 5.46243 2.5 8.5 2.5C11.5376 2.5 14 4.96243 14 8C14 11.0376 11.5376 13.5 8.5 13.5ZM8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 4.68629 11.8137 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14Z" fill="currentColor" />
    </svg>,
    'Coin'
);

export default Coin;
