import { Box, Divider } from "@mui/material";
import React from "react";
import { calculateTotalJackpotAmount, getUniquePayCurrency, calculateTotalJackpot } from "src/main/utils";
import { JackpotListItem, JackpotTitle } from "./components";
import { CouponDetail } from "src/main/types";

interface JackpotListProps {
  couponDetails: CouponDetail;
}

const JackpotList: React.FC<JackpotListProps> = (props) => {
  const { couponDetails } = props;
  const totalJackpotAmount = calculateTotalJackpotAmount(couponDetails);
  const totalJackpot = calculateTotalJackpot(couponDetails);
  const payCurrency = getUniquePayCurrency(couponDetails);
  const totalNormalPrize = couponDetails.winnerLimit ?? couponDetails.maxRedemption;
  const normalPrize = {
    amount: "",
    name: "",
    payCurrency: couponDetails.payCurrency,
    remaining: totalNormalPrize - totalJackpot,
    minValue: couponDetails.minValue,
    maxValue: couponDetails.maxValue
  }

  return (
    <Box pt={1} sx={{ display: 'flex', justifyContent: 'center' }} >
      <Box sx={{ width: "100%", position: "relative" }}>
        {(couponDetails?.jackpots && couponDetails.jackpots.length !== 0) && (
          <JackpotTitle totalJackpotAmount={totalJackpotAmount} payCurrency={payCurrency} />
        )}
        {couponDetails?.jackpots.map((jackpot, index) => (
          <Box key={index}>
            <JackpotListItem prizeItem={jackpot} />
            <Divider sx={{ borderBottomWidth: 2 }} />
          </Box>
        ))}
        <JackpotListItem prizeItem={normalPrize} />
      </Box>
    </Box >
  );
};

export default JackpotList;