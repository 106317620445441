import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from "dayjs";
import { SimpleMap } from 'src/main/utils';
import { LoadingTasks, NewLoadingTask } from './types';

export type LayoutState = {
  loadingTasks: LoadingTasks;
  tasksRegistry: SimpleMap<any>;
}

export const initialState: LayoutState = {
  loadingTasks: {},
  tasksRegistry: {},
}

const layoutSlice = createSlice({
  name: '@layout',
  initialState,
  reducers: {
    addLoadingTask(state, action: PayloadAction<NewLoadingTask>) {
      const newTask = action.payload;
      const loadingTask = state.loadingTasks[newTask.name] || {};
      loadingTask[newTask.uuid] = dayjs();
      state.loadingTasks[newTask.name] = loadingTask;
      state.tasksRegistry[newTask.uuid] = newTask.name;
    },
    removeLoadingTask(state, action: PayloadAction<string>) {
      const uuid = action.payload;
      const taskName = state.tasksRegistry[uuid];
      if (!taskName)
        return state;

      const loadingTask = state.loadingTasks[taskName];
      if (!loadingTask || !loadingTask[uuid])
        return state;

      delete loadingTask[uuid]
      if (!Object.keys(loadingTask).length)
        delete state.loadingTasks[taskName!];

      delete state.tasksRegistry[uuid];
    },
  },
})

export default layoutSlice;
