import { createSvgIcon } from '@mui/material/utils';

const Alert = createSvgIcon(
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM8.5 4C7.96459 4 7.54623 4.46229 7.5995 4.99504L7.95025 8.50248C7.97849 8.78492 8.21616 9 8.5 9C8.78384 9 9.02151 8.78492 9.04975 8.50248L9.4005 4.99504C9.45377 4.46228 9.03541 4 8.5 4ZM8.50154 10C7.94926 10 7.50154 10.4477 7.50154 11C7.50154 11.5523 7.94926 12 8.50154 12C9.05383 12 9.50154 11.5523 9.50154 11C9.50154 10.4477 9.05383 10 8.50154 10Z" fill="currentColor" />
  </svg>,
  'Alert'
);

export default Alert;
