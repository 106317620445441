import { Box, Typography } from "@mui/material";
import React from "react";

interface PageNotFoundProps extends React.PropsWithChildren {

}

const PageNotFound: React.FC<PageNotFoundProps> = (props) => {
  return (
    <Box>
      <Typography>Not Found</Typography>
    </Box>
  );
};

export default PageNotFound;