import { ThemeProvider } from "@mui/material/styles";
import React, { PropsWithChildren } from "react";
import { IntlProvider } from 'react-intl';
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { RootState } from "src/main/store";
import { Paths } from "src/main/utils";
import { ThemeMode } from "./main/store/preference/types";
import { createTheme } from "./main/theme";
import { browserLanguageSetting, messages } from './main/utils/localization';
import { Portal } from "./main/views";
import PageNotFound from "./main/views/portal/Main/PageNotFound";
import { GoogleAnalytics } from "./main/components";
import CampaignList from "./main/views/portal/Main/CampaignList";
import { Toaster } from "./main/components";


export interface AppProps extends PropsWithChildren {

}
const App: React.FC<AppProps> = (props) => {
  const savedTheme = useSelector<RootState, ThemeMode>(state => state.preference.theme);
  const theme = createTheme({ mode: savedTheme ?? "dark" });
  const savedLanguage = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;

  return (
    <IntlProvider locale={savedLanguage} messages={messages[savedLanguage]}>
      <ThemeProvider theme={theme}>
        <Toaster theme={theme} />
        <GoogleAnalytics />
        <Routes>
          {/* <Route path={`${Paths.Portal.Index}/*`} element={<Portal.Main />} /> */}
          <Route path={`${Paths.Portal.Index}/*`} element={<Portal.Main />} />
          <Route path={`${Paths.Portal.NotFound}`} element={<PageNotFound />} />
          <Route path={`${Paths.Portal.CampaignList}/*`} element={<CampaignList />} />
          <Route path="/" element={<Navigate to={`${Paths.Portal.CampaignList}`} />} />
        </Routes>
      </ThemeProvider>
    </IntlProvider>
  );
}
export default App;

// FOR FUTURE USE:
// function useStyles() {
//   throw new Error("Function not implemented.");
// }

