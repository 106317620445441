import { Avatar, Box, Grid, Chip, useTheme } from "@mui/material";
import { Check, Clock, ColdCoinGif, Alert, Person, PersonJackpot } from 'src/assets';
import { Typography } from "src/main/components";
import { createStyles, joinSx, localizationDate, localizationTime, IntlFormatter, convertFormatNumber } from "src/main/utils";

interface WinnerListItemProps {
  aliasUsername: string,
  status: string,
  amount: string,
  currency: string,
  createdAt: Date,
  isLottery: boolean,
}

const WinnerListItem: React.FC<WinnerListItemProps> = (props) => {
  const { aliasUsername, status, amount, currency, createdAt, isLottery } = props;
  const theme = useTheme();
  const getStatusMessage = (message: string) => {
    return <Typography formatId={`winner.${message}`} variant="caption" display="block" lineHeight={0.85}>{message}</Typography>
  }

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "invalid":
        return <><Alert color="error" sx={joinSx(styles.statusIcon)} />{getStatusMessage(status)}</>;
      case "credited":
        return <><Check color="success" sx={joinSx(styles.statusIcon)} />{getStatusMessage(status)}</>;
      default:
        return <><Clock sx={joinSx({ color: 'text.secondary' }, styles.statusIcon)} />{getStatusMessage(status)}</>;
    }
  }

  return (
    <Box mt={1}>
      <Box sx={{ backgroundColor: isLottery ? "#ffffff" : "#F6EEFD", borderRadius: 1, boxShadow: 3 }} py={1} px={2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={joinSx(styles.itemBox)}>
            <Avatar sx={{ bgcolor: isLottery ? "#FFAA06" : "#ffffff", width: 24, height: 24 }}>
              {isLottery ? <PersonJackpot sx={{ fontSize: 16, px: .5 }} /> : <Person sx={{ fontSize: 16, px: .5 }} />}
            </Avatar>
            <Box px={1}>
              <Box sx={joinSx(styles.itemBox)}>
                <Typography variant="caption" fontWeight={700} display="block" lineHeight={0.85}>
                  {aliasUsername}
                </Typography>

                <Box sx={joinSx({ justifyContent: 'space-between' }, styles.itemBox)}>
                  {getStatusIcon(status)}
                </Box>
              </Box>
              <Grid container>
                <Grid item>
                  <Typography variant="caption" color="text.secondary" fontWeight={700} display="block" lineHeight={1.33} >{localizationTime(createdAt)}</Typography>
                </Grid>
                <Typography px={.5} variant="caption" color="text.secondary" display="block" lineHeight={1.33} >|</Typography>
                <Grid item >
                  <Typography variant="caption" color="text.secondary" fontWeight={700} display="block" lineHeight={1.33} >{localizationDate(createdAt)}</Typography>
                </Grid>
              </Grid>

            </Box>
          </Box>
          <Box sx={joinSx(styles.itemBox)} width={100}>

            <Box sx={{ flexDirection: 'column', }}>
              <Box px={.5}>
                {isLottery && (<Chip label={IntlFormatter.getMessage("winner.jackpot", 'Jackpot!')} color="warning" size="small" sx={{ fontSize: 14, fontWeight: 700, width: "100%" }} />)}
              </Box>
              <Box display="flex" mt={.2}>
                <Box
                  px={.5}
                  component="img"
                  src={ColdCoinGif}
                />
                <Typography variant="body2" fontWeight={700} color={isLottery ? theme.palette.warning.dark : 'primary'}>
                  {currency}{convertFormatNumber(parseFloat(amount), currency)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

const styles = createStyles({
  itemBox: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    fontSize: 16,
    px: .5,
    lineHeight: 0.85,
  }
});

export default WinnerListItem;