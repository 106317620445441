import { createSvgIcon } from '@mui/material/utils';

const Whatsapp = createSvgIcon(
    <svg viewBox="0 -1 17 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.87603 13.0538L0.703918 17.3333L5.08336 16.1849C6.29005 16.8429 7.64855 17.1897 9.03108 17.1903H9.03467C13.5878 17.1903 17.2941 13.485 17.2961 8.93161C17.2969 6.72482 16.4386 4.64986 14.8787 3.0888C13.3188 1.52775 11.2446 0.667779 9.03478 0.666656C4.48097 0.666656 0.775217 4.37128 0.773309 8.92499C0.771559 10.3743 1.15191 11.7984 1.87603 13.0538ZM6.50542 5.11582C6.6524 5.12177 6.81521 5.12873 6.96993 5.47243C7.07531 5.70693 7.25249 6.14359 7.39401 6.49235C7.49886 6.75077 7.58414 6.96093 7.60612 7.00485C7.65777 7.10804 7.69202 7.22885 7.6233 7.3664C7.61274 7.38754 7.60299 7.40747 7.59369 7.42649C7.54248 7.53123 7.50466 7.60858 7.41715 7.71054C7.38218 7.75128 7.34604 7.79522 7.30993 7.83913C7.23923 7.92507 7.16868 8.01085 7.10736 8.07209C7.00384 8.17494 6.89605 8.28677 7.01664 8.49326C7.13723 8.69975 7.55144 9.37546 8.16506 9.92261C8.82551 10.5115 9.39904 10.7602 9.68939 10.886C9.74575 10.9105 9.79145 10.9303 9.82503 10.9471C10.0316 11.0503 10.1519 11.033 10.2724 10.8952C10.3928 10.7574 10.788 10.2927 10.9257 10.0861C11.0635 9.87949 11.201 9.91374 11.3902 9.98268C11.5794 10.0516 12.5941 10.5509 12.8007 10.6542C12.8414 10.6746 12.8793 10.6929 12.9145 10.7098C13.0581 10.7791 13.1551 10.8258 13.1965 10.8952C13.2481 10.9815 13.2479 11.3945 13.076 11.8765C12.9041 12.3586 12.0615 12.8235 11.6828 12.8579C11.6464 12.8612 11.6102 12.8654 11.5729 12.8698C11.223 12.9109 10.7816 12.9628 9.2058 12.3414C7.26491 11.576 5.9857 9.6785 5.72319 9.28912C5.70191 9.25755 5.68732 9.2359 5.67959 9.22556L5.67714 9.22229C5.56536 9.07283 4.8368 8.09865 4.8368 7.09075C4.8368 6.1406 5.30371 5.64234 5.51872 5.41289C5.53353 5.39708 5.54715 5.38256 5.55933 5.36924C5.74842 5.16264 5.97208 5.11099 6.10974 5.11099L6.50542 5.11582Z" fill="currentColor" />
    </svg>,
    'Whatsapp'
);

export default Whatsapp;
