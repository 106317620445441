import { Box, BoxProps, Chip, TablePagination, Typography, SxProps, Pagination } from '@mui/material';
import { Dayjs } from 'dayjs';
import moment from 'moment';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EmptyState, InputField, LoadingContainer, Scrollbar } from "src/main/components";
import { Meta, Option } from "src/main/types";
import { joinSx, normalizeText, SimpleMap } from 'src/main/utils';
import { DateFilter, DateSelectDialog, Searchbar, Sortbar } from "./components";
import { DateSelectDialogDates } from './components/DateSelectDialog/DateSelectDialog';

const ListingComponentContext = createContext<{
  persistMeta: Meta,
  updateList: (meta: Meta) => void,
  loading?: boolean,
  statusValue?: SimpleMap<string>;
  setStatusValue?: (val: SimpleMap<string>) => void;
  defaultValue?: SimpleMap<string>;
  setDefaultValue?: (val: SimpleMap<string>) => void;
}>({
  persistMeta: { count: 0, limit: 10, offset: 0 },
  updateList: () => { },
  loading: false,
  statusValue: {},
  setStatusValue: () => { },
  defaultValue: {},
  setDefaultValue: () => { },
});

interface StatusbarCompProps extends BoxProps {
  label?: string;
  options: Option[];
  onStatusUpdate?: (status: string, datesParam?: string) => void;
  defaultSelected?: string;
  onTimeChange?: (timeParam: string) => void;
  metaKey?: string;
}

const StatusbarComp = (props: StatusbarCompProps) => {
  const {
    label = "Status", options, onStatusUpdate,
    defaultSelected = "all", onTimeChange, metaKey = "status", ...rest
  } = props;
  const { persistMeta, updateList, statusValue, setStatusValue, setDefaultValue, defaultValue } = useContext(ListingComponentContext);

  useEffect(() => {
    if (defaultValue?.[metaKey]) return;
    setDefaultValue?.({ ...defaultValue, [metaKey]: defaultSelected });
    // eslint-disable-next-line
  }, [setDefaultValue, defaultValue])

  const onOptionChange = (opt: Option) => {
    onStatusUpdate?.(opt.value + "");

    setStatusValue?.({ ...statusValue, [metaKey]: opt.value + "" });

    if (!onStatusUpdate) {
      updateList({ ...persistMeta, [metaKey]: opt.value, offset: 0 });
    }
  }

  const selectedValue = useMemo(() => {
    return statusValue?.[metaKey] ?? defaultValue?.[metaKey] ?? defaultSelected;
    // eslint-disable-next-line
  }, [statusValue, defaultValue])

  return (
    <Box sx={{
      minWidth: { sm: 130 }
    }} {...rest}>
      <Sortbar
        defaultSelected={defaultSelected}
        label={label}
        options={options}
        onOptionChange={onOptionChange}
        value={selectedValue}
      />
    </Box>
  )
}

interface DateLabelInputProps extends BoxProps {
  label: string;
  onDateChanged?: (timeParam: string) => void;
  dateChangeKey: string;
  resetStatus?: string[];
}

const DateLabelInput = (props: DateLabelInputProps) => {
  const {
    onDateChanged, dateChangeKey, resetStatus = [], ...rest
  } = props;
  const { persistMeta, updateList, setStatusValue, defaultValue, statusValue } = useContext(ListingComponentContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [dates, setDates] = useState<DateSelectDialogDates | null>(null);

  const { startDate, endDate } = useMemo(() => {
    if (!dates) return {};
    return {
      startDate: dates.start ? moment(dates.start.toDate()).format("YYYY-MM-DD") : null,
      endDate: dates.end ? moment(dates.end.toDate()).format("YYYY-MM-DD") : null,
    }
  }, [dates])

  useMemo(() => {

    if (statusValue?.visibility && statusValue?.visibility !== "all") {
      return setDates(null)
    }

    if (statusValue?.status && statusValue?.status !== "all") {
      return setDates(null)
    }
  }, [statusValue?.visibility, statusValue?.status])

  const onDatesConfirm = (dates: DateSelectDialogDates | null) => {
    setDates(dates)
    const newMeta = { ...persistMeta };

    delete newMeta[dateChangeKey];
    if (!dates || (!dates?.start && !dates?.end)) {
      return updateList({
        ...newMeta
      })
    }

    if (newMeta.status === "expiry") {
      delete newMeta.expiry
    }

    if (newMeta.status === "start") {
      delete newMeta.start
    }

    delete newMeta.status;
    delete newMeta.ongoing;
    delete newMeta.visibility;
    delete newMeta.publishedAt;

    if (resetStatus.length) {
      const newStatus = { ...statusValue };
      resetStatus.forEach((stat) => {
        if (defaultValue?.[stat]) {
          newStatus[stat] = defaultValue?.[stat]
        }
      })
      setStatusValue?.(newStatus);
    }

    let updateStr = "";
    if (dates.start && dates.end) {
      updateStr = `${moment(dates.start.toDate()).unix()},${moment(dates.end.toDate()).unix()}`;
    } else if (dates.start && !dates.end) {
      updateStr = `${moment(dates.start.toDate()).unix()},`;
    } else if (!dates.start && dates.end) {
      updateStr = `0,${moment(dates.end.toDate()).unix()}`;
    }

    updateList({
      ...newMeta,
      [dateChangeKey]: updateStr
    })
  }

  const getAdornment = () => {
    if (dates?.start && dates?.end) {
      return (
        <>
          <Chip sx={{ ml: 1, cursor: "pointer" }} label={startDate} /><Typography >-</Typography><Chip sx={{ cursor: "pointer" }} label={endDate} />
        </>
      )
    } else if (dates?.start && !dates?.end) {
      return (
        <>
          <Typography sx={{ mr: 1 }}>From</Typography><Chip sx={{ cursor: "pointer" }} label={startDate} />
        </>
      )
    } else if (!dates?.start && dates?.end) {
      return (
        <>
          <Typography sx={{ mr: 1 }}>Until</Typography><Chip sx={{ cursor: "pointer" }} label={endDate} />
        </>
      )
    } else {
      return undefined
    }
  }

  return (
    <Box sx={{
      minWidth: { md: 180, sm: "100%" },
      maxWidth: { md: 260, sm: "100%" }
    }} {...rest}>
      <InputField
        onClick={() => setOpenDialog(true)}
        label={`${normalizeText(dateChangeKey)} dates`}
        placeholder={(!dates?.start && !dates?.end) ? 'Select dates' : undefined}
        sx={{ cursor: "pointer" }}
        InputProps={{
          readOnly: true,
          startAdornment: !!dates && getAdornment(),
        }}
      />

      <DateSelectDialog
        open={openDialog}
        onConfirm={onDatesConfirm}
        onClose={() => setOpenDialog(false)}
      />
    </Box >
  )
}

interface SearchbarCompProps extends BoxProps {
  label?: string;
  onSearchChange?: (search: string) => void;
}

const SearchbarComp = (props: SearchbarCompProps) => {
  const { label, onSearchChange, ...rest } = props;
  const { persistMeta, updateList } = useContext(ListingComponentContext);

  const onChange = (search: string) => {
    onSearchChange?.(search);
    if (!onSearchChange) updateList({ ...persistMeta, search });
  }

  return (
    <Box sx={{ flexGrow: 1 }} {...rest}>
      <Searchbar
        label={label}
        onSearchChange={onChange}
      />
    </Box>
  )
}

interface FilterSectionProps extends BoxProps {
  children: ReactNode;
}

const FilterSection = (props: FilterSectionProps) => {
  const { children, sx: sxProps, p = 2, ...rest } = props;

  return (
    <Box sx={joinSx({ display: "flex", flexDirection: { md: "row", sm: "column", xs: "column" } }, sxProps)} gap={2} p={p} {...rest}>
      {children}
    </Box>
  )
}

interface ListingContentProps {
  children: ReactNode
}

const ListingContent = (props: ListingContentProps) => {
  const { children } = props;
  const { persistMeta } = useContext(ListingComponentContext);

  return (
    <>
      {!!(persistMeta.count && persistMeta.count > 0) && (
        <Scrollbar>
          {children}
        </Scrollbar>
      )}
      {!!(!persistMeta.count || persistMeta.count < 1) && (
        <EmptyState />
      )}
    </>
  )
}

interface DateFilterCompProps extends BoxProps {
  onDateChange?: (string) => void;
  dateSearchKey: string;
}

const DateFilterComp = (props: DateFilterCompProps) => {
  const { onDateChange, //md = 12, sm = 12, lg = 12, 
    dateSearchKey, ...rest } = props;
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const { persistMeta, updateList } = useContext(ListingComponentContext);

  const updateDates = (start: Dayjs | null, end: Dayjs | null) => {
    if (!start || !end) return;

    const dateParam = `${moment(start.toDate()).startOf("day").unix()},${moment(end.toDate()).endOf("day").unix()}`

    onDateChange?.(dateParam);
    if (!onDateChange) {
      updateList({
        ...persistMeta,
        [dateSearchKey]: dateParam,
      })
    }

  }

  const onStartChange = (date: Dayjs | null) => {
    setStart(date);

    updateDates(date, end);
  }

  const onEndChange = (date: Dayjs | null) => {
    setEnd(date);

    updateDates(start, date);
  }

  return (
    <Box sx={{
      minWidth: { sm: 130 }
    }} {...rest}>
      <DateFilter
        startValue={start}
        endValue={end}
        onStartDateChange={onStartChange}
        onEndDateChange={onEndChange}
      />
    </Box>
  )
}

interface ListingProps {
  hidePagination?: boolean;
  updateList: (meta: Meta) => void;
  meta?: Meta;
  children: ReactNode;
  title: string;
  loading?: boolean;
  sx?: SxProps;
  mode?: "admin" | "tasker";
}
const Listing = (props: ListingProps) => {
  const {
    loading, hidePagination = false, meta = { count: 0, limit: 10, offset: 0 }, mode = "admin",
    updateList, children, sx: sxProps
  } = props;

  const [rowsPerPage, setRowsPerPage] = useState(meta.limit);
  const [statusValue, setStatusValue] = useState<SimpleMap>({});
  const [defaultValue, setDefaultValue] = useState<SimpleMap>({});

  const wrapUpdateList = useCallback((newMeta: Meta) => {
    updateList({ ...newMeta });
    // eslint-disable-next-line
  }, [meta, updateList])


  const onRowsPerPageChange = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPage = (parseInt(val.target.value) ?? 0);

    setRowsPerPage(newPage);
    wrapUpdateList({
      ...meta,
      limit: newPage,
      offset: 0
    })
  }

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    wrapUpdateList({
      ...meta,
      limit: rowsPerPage,
      offset: rowsPerPage * page
    })
  }

  const onTaskerPageChange = (event: React.ChangeEvent<unknown> | null, page: number) => {
    console.log({ page })
    wrapUpdateList({
      ...meta,
      limit: rowsPerPage,
      offset: rowsPerPage * (page - 1)
    })
  }

  const taskerPageCount = !!meta.count ? Math.ceil(meta.count / meta.limit) : 0;
  const taskerPage = meta.offset === 0 ? 1 : (meta.offset / meta.limit) + 1

  return (
    <ListingComponentContext.Provider value={{ defaultValue, setDefaultValue, statusValue, setStatusValue, persistMeta: meta, updateList: wrapUpdateList, loading }}>
      <Box
        sx={joinSx({ justifyContent: "center" }, sxProps)}
      >
        <LoadingContainer loading={loading}>
          {children}
          {!hidePagination && (
            <>
              {mode === "admin" && (
                <TablePagination
                  component="div"
                  count={meta.count ?? 0}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  page={meta.offset === 0 ? 0 : (meta.offset / meta.limit)}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                />
              )}
              {mode === "tasker" && (
                <Pagination
                  sx={{
                    "&.MuiPagination-root > ul": {
                      justifyContent: "center",
                      '& .Mui-selected': {
                        backgroundColor: 'transparent', // Remove background color on selected number
                        color: "#005CDB",
                        fontWeight: 700
                      },
                      '& .MuiPaginationItem-root': {
                        backgroundColor: 'transparent', // Remove background color on other numbers
                      },
                    },
                  }}
                  page={taskerPage}
                  count={taskerPageCount}
                  onChange={onTaskerPageChange}
                  hidePrevButton
                  hideNextButton
                />
              )}
            </>
          )}
          {hidePagination && (
            <Typography variant="body2" color="textSecondary" p={2}>Showing {Math.min(meta.limit, meta.count ?? 0)} of {meta.count ?? 0} records…</Typography>
          )}
        </LoadingContainer>
      </Box>
    </ListingComponentContext.Provider>
  )
};

Listing.Statusbar = StatusbarComp;
Listing.Searchbar = SearchbarComp;
Listing.FilterSection = FilterSection;
Listing.Content = ListingContent
Listing.DateFilter = DateFilterComp;
Listing.DateLabelInput = DateLabelInput;

export default Listing;
