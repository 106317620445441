const normalizeText = (input?: string | null | undefined, normaliseType?: "title" | "sentence" | "letter") => {
  if (!input) return "";
  let type = normaliseType;
  if (!type) type = "title";
  const newString: string[] = [];

  if (type === "letter") {
    const str: string[] = input.split(" ");
    str.forEach((char) => {
      const monoStr = char.trim().split("");
      const newMono: string[] = [];
      monoStr.forEach((mChar, index) => {
        if (index === 0) {
          newMono.push(mChar.toUpperCase());
        } else {
          newMono.push(mChar.toLowerCase())
        }
      })
      newString.push(newMono.join(""));
    });
    return newString.join(" ");
  } else {
    const strArr: string[] = input.trim().split("");
    strArr.forEach((char, index) => {
      if (index === 0) {
        newString.push(char.toUpperCase());
      } else if (char.toUpperCase() === char) {
        newString.push(" ");
        newString.push(type === "title" ? char : char.toLowerCase());
      } else {
        newString.push(char);
      }
    })
    return newString.join("");
  }

}

export default normalizeText;