import { createSvgIcon } from '@mui/material/utils';

const LightningIcon = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.07842 1.10892C3.14101 0.896125 3.3363 0.75 3.5581 0.75H7.5581C7.71882 0.75 7.86975 0.827261 7.96372 0.957645C8.0577 1.08803 8.08327 1.25564 8.03244 1.40811L6.25182 6.75H10.0581C10.2491 6.75 10.4234 6.85878 10.5073 7.03032C10.5912 7.20187 10.57 7.40623 10.4528 7.55697L3.45278 16.557C3.30459 16.7475 3.04144 16.805 2.82731 16.6936C2.61319 16.5821 2.50923 16.3337 2.58021 16.103L4.38112 10.25H1.0581C0.900525 10.25 0.752168 10.1757 0.657764 10.0495C0.56336 9.92338 0.533956 9.76009 0.578419 9.60892L3.07842 1.10892Z"
            fill="currentColor"
        />
    </svg>,
    'LightningIcon'
);

export default LightningIcon;

