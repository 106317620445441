import { Box } from "@mui/material";
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from "react-router-dom";
import useWebSocket from 'react-use-websocket';
import { Typography, ListingComp } from "src/main/components";
import { WinnerListItem } from "./components";
import { getDomain, getWebsocketBaseURL } from "src/main/utils";
import { Meta } from "src/main/types";

interface WinnerListProps {
}
const WinnerList: React.FC<WinnerListProps> = (props) => {

  const webSocketBaseURL = getWebsocketBaseURL();
  const domain = getDomain();
  const { handleKey = "" } = useParams<{ handleKey: string }>();

  const [meta, setMeta] = useState<Meta>({ offset: 0, limit: 10, count: 0 });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const metaQuary = `&limit=${meta.limit}&offset=${meta.offset}&count=${meta.count}`;
  const webSocketURL = webSocketBaseURL + `?domain=${domain}&handle=${handleKey}` + metaQuary;
  const { lastMessage } = useWebSocket(webSocketURL);

  interface Entry {
    aliasUsername: string,
    status: string,
    amount: string,
    currency: string,
    createdAt: Date,
    isLottery: boolean,
  }

  interface Message {
    entries: Entry[],
    meta: Meta;
  }

  const [messages, setMessages] = useState<Message>();

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const data = JSON.parse(lastMessage.data) as Message;
      if (data.entries.length) {
        setMessages(data);
      }
      setIsLoading(false);
    }
  }, [lastMessage]);

  const winnerListing = useMemo(() => messages ?? undefined, [messages]);
  const persistMeta = useMemo(() => (
    { ...meta, ...messages?.meta }
  ), [messages, meta]);
  return (

    <Box textAlign="center">
      {messages &&
        (
          <>
            <Typography formatId="winner.winners" variant="h4" fontWeight={700}>
              Winners
            </Typography>
            <Typography
              variant="subtitle2" color="text.secondary" fontWeight={700}
              formatId="winner.congratulation"
              defaultMessage={`Congratulations to our ${messages.meta.count} winners!`}
              formatValues={{ number: messages.meta.count ?? 0 }}
            >
            </Typography>
          </>
        )
      }
      <Box>
        <ListingComp
          loading={isLoading}
          title=""
          updateList={(newMeta) => { setMeta(newMeta); }}
          meta={persistMeta}
          mode="tasker"
          sx={{ minHeight: "200px", py: 1 }}
        >
          {winnerListing?.entries?.map((message, index) => (
            <WinnerListItem key={index} {...message} />
          ))}
        </ListingComp>
      </Box>
    </Box>

  );
}

export default WinnerList;