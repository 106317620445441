import { BuilderProp, require200Status } from "src/main/api/public";
import { QueryResult } from "src/main/types";
import { parseMeta } from "src/main/utils/parseMeta";
import { CouponDetail, socialMediaModel } from "src/main/types";

export interface StatusResponse {
  status: string;
}

export interface GetCouponInfomationRequest {
  meta: {
    domain: string;
    handle: string;
  }
}

interface SliderImage {
  url: string;
  mimeType: string;
}

export interface GetCouponInformationResponse {
  coupon: CouponDetail;
  page: {
    brandHandle: string;
    brandTitle: string;
    brandSiteHandle: string;
    brandName: string
    brandBanner: {
      url: string;
      mimeType: string;
    };
    defaultLanguage: string,
    broadcastLink: string,
    broadcastType: string,
    socials: socialMediaModel[],
  };
  totalRedeemed: number;
  remainingRedeem: number;
  sliders?: {
    image: SliderImage;
  }[];
}

export interface GetCouponPageRequest {
  couponPageMeta: {
    domain: string;
  }
}
export interface GetCouponPageResponse {
  page: {
    brandHandle: string;
    brandTitle: string;
    brandSiteHandle: string;
    brandBanner: {
      url: string;
      mimeType: string;
    };
    brandLogo: {
      url: string;
      mimeType: string;
    };
    defaultLanguage: string,
  };
}

export const getCouponInformation = (builder: BuilderProp) => {
  return builder.query<GetCouponInformationResponse, GetCouponInfomationRequest>({
    providesTags: ["coupon"],
    query: ({ meta }) => ({
      url: `/coupon?${parseMeta(meta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetCouponInformationResponse>) => {
      return response.result;
    }
  })
}
export const getCouponPage = (builder: BuilderProp) => {
  return builder.query<GetCouponPageResponse, GetCouponPageRequest>({
    providesTags: ["coupon"],
    query: ({ couponPageMeta }) => ({
      url: `/coupon/page?${parseMeta(couponPageMeta)}`,
      validateStatus: require200Status,
    }),
    transformResponse: (response: QueryResult<GetCouponPageResponse>) => {

      return response.result;
    }
  })
}

export const getCouponStatus = (builder: BuilderProp) => {
  return builder.mutation({
    query: (payload) => ({
      url: "/coupon/status",
      method: "POST",
      body: payload,
      validateStatus: require200Status,
    }),
    transformResponse: (response) => {
      return response
    }
  })
}

export const redeemCoupon = (builder: BuilderProp) => {
  return builder.mutation({
    invalidatesTags: ["coupon"],
    query: (payload) => ({
      url: "/redeem",
      method: "POST",
      body: payload,
      validateStatus: require200Status,
    }),
    transformResponse: (response) => {
      return response
    }
  })
}
