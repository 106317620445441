import { Box, Button, Card, CardActions, CircularProgress, TextField, useTheme } from "@mui/material";
import { useFormik } from 'formik';
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CoinIcon, Warning, Subtract } from 'src/assets';
import { Defaults } from "src/main/constants";
import { createStyles, errorMessages, joinSx, checkIfExpired, checkIfFuture, getDomain, handleApiResponse, errorFieldMaping } from "src/main/utils";
import * as Yup from "yup";
import { ConfirmDialog, RedeemFailed, SuccessDialog, CountdownTimer, WinnerList, CriteriaList, FormTitleMessage, LuckyDrawSuccessDialog, Banner, JackpotList, BroadcastLink, SignupButton } from "./components";
import { IntlFormatter } from "src/main/utils";
import { Typography, BackButton } from "src/main/components";
import { Paths } from "src/main/utils";
import { publicApi } from "src/main/api";
import { GetCouponInformationResponse } from "src/main/api/public/Coupon";
import { useAsyncTask } from "src/main/hooks";
import { LanguageSwithcer } from "../components";
import { CampaignType } from 'src/main/constants';

interface HomeProps extends React.PropsWithChildren {

}

interface HandleParams {
  handle: string;
  domain: string;
}

interface Redeem {
  redeem: {
    amount: string,
    code: string,
    currenty: string,
    username: string,
  }
};

interface RedeemResult {
  result: Redeem
};

interface ValidateCouponResponse {
  result: {
    valid: boolean
  }
}

interface CombinedImageData {
  url: string | undefined;
  mimeType: string | undefined;
}

const Home: React.FC<HomeProps> = (props) => {
  const theme = useTheme();
  const [username, setUsername] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [creditWon, setCreditWon] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [redeemFailed, setRedeemFailed] = useState<boolean>(false);
  const { handleKey = "" } = useParams<{ handleKey: string }>();
  const [countdownCompleted, setCountdownCompleted] = useState(false);

  const [couponStatus, setCouponStatus] = useState<boolean>();
  const [usernameStatus, setUsernameStatus] = useState<boolean>();
  const isSecretCodeValid = useMemo(() => couponStatus ?? false, [couponStatus]);
  const isUsernameValid = useMemo(() => usernameStatus ?? true, [usernameStatus]);

  const domain = getDomain();

  const meta: HandleParams = {
    handle: handleKey,
    domain
  }

  const result = publicApi.useGetCouponInformationQuery({ meta });
  const couponInformation: GetCouponInformationResponse | undefined = useMemo(() => result.data ?? undefined, [result.data]);

  const [submitCouponStatusQuery] = publicApi.useGetCouponStatusMutation();
  const [runSubmitCouponStatus, loading] = useAsyncTask("coupon/status");
  const [submitRedeemCouponQuery] = publicApi.useRedeemCouponMutation();
  const [runRedeemCoupon, loadingRedeem] = useAsyncTask("coupon/redeem");


  const startTime = couponInformation?.coupon?.start;
  const expiry = couponInformation?.coupon?.expiry;
  const expired = useMemo(() => {
    return checkIfExpired(expiry)
    // eslint-disable-next-line
  }, [expiry, countdownCompleted]
  );
  const isUpcoming = useMemo(() => {
    return checkIfFuture(startTime)
    // eslint-disable-next-line
  }, [startTime, countdownCompleted]);

  const combinedImages: CombinedImageData[] = useMemo(() => {
    const brandBannerImage: CombinedImageData = {
      url: couponInformation?.page?.brandBanner?.url ?? "",
      mimeType: couponInformation?.page?.brandBanner?.mimeType ?? "",
    };
    const sliderImages: CombinedImageData[] | undefined = couponInformation?.sliders?.map((slider) => ({
      url: slider.image.url,
      mimeType: slider.image.mimeType,
    }));

    return [brandBannerImage, ...(sliderImages || [])];
  }, [couponInformation]);

  const isOngoingLuckyDraw = useMemo(() => {
    return (couponInformation?.coupon?.type === CampaignType.LuckyDraw && !expired)
  }, [expired, couponInformation]);

  const isFullyRedeem = useMemo(() => {
    return (((couponInformation?.remainingRedeem ?? 0) <= 0 || expired) && !!startTime)
  }, [expired, couponInformation, startTime]);

  useEffect(() => {
    document.title = couponInformation?.page?.brandTitle || Defaults.DocumentTitle;
  }, [couponInformation?.page?.brandTitle]);

  const RedeemStatus = useMemo(() => {
    if (redeemFailed) {
      return {
        upcoming: false,
        fully: true,
        invalid: false,
      }
    }

    let max = couponInformation?.totalRedeemed

    if (!!couponInformation?.totalRedeemed) {
      if (!!couponInformation.coupon && !!couponInformation.coupon.maxRedemption) {
        if (couponInformation.totalRedeemed < couponInformation.coupon.maxRedemption) {
          max = couponInformation?.totalRedeemed
        } else {
          max = couponInformation.coupon.maxRedemption
        }
      }
    }
    return {
      upcoming: isUpcoming,
      fully: couponInformation?.coupon?.type === CampaignType.LuckyDraw ? !isOngoingLuckyDraw : isFullyRedeem,
      invalid: ((couponInformation?.remainingRedeem === 0 && couponInformation?.totalRedeemed === 0) ?? false) && isOngoingLuckyDraw,
      max: max,
    }
    // eslint-disable-next-line
  }, [couponInformation, redeemFailed, isOngoingLuckyDraw, isFullyRedeem, isUpcoming])

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.values.username = event.target.value;
    setUsername(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    secretCode: Yup.string().required("general.secret_code_is_required").test(
      "validate-secret-code",
      "general.invalid_secret_code",
      () => isSecretCodeValid // Use the validity state to determine if the code is valid or not
    ),
    username: Yup.string().required("general.username_is_required").test(
      "validate-username",
      "general.username_not_qualified",
      () => isUsernameValid // Use the validity state to determine if the username is valid or not
    )
  });


  interface FormValues {
    secretCode: string;
    username: string;
  }

  const formik = useFormik({
    initialValues: {
      secretCode: '',
      username: username,
    },
    validate: values => {

      const errors: Partial<FormValues> = {};
      if (!values.secretCode && values.username) {
        setUsernameStatus(false);
        errors.username = 'general.secret_code_is_required';
      }

      if (!values.secretCode) {
        setCouponStatus(false);
        errors.secretCode = 'general.secret_code_is_required';
      }

      return errors;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDialogOpen(true);
    },
  });

  const toSubmit = () => {
    runRedeemCoupon(async () => {
      const code = formik.values.secretCode;
      const result = await submitRedeemCouponQuery({ code, domain, username });
      const { data: parsedData, error } = handleApiResponse<RedeemResult>(result);
      if (error?.data?.error) {
        const field = Object.keys(error.data.error?.errors)[0];
        const errorMessageKey = error?.data?.error?.message;
        if (errorMessageKey && errorMessageKey in errorMessages) {
          switch (errorMessageKey) {
            case "code expired":
            case "max redemption reached":
              setRedeemFailed(true);
              break;
            default:
              formik.setFieldError(field, errorMessages[errorMessageKey as keyof typeof errorMessages]);
              break;
          }
        } else {
          formik.setFieldError(field, errorMessages.default);
        }
      }

      if (parsedData) {
        setCreditWon(parsedData?.result?.redeem?.amount ?? '0'); // to launch success popup with credit won.
        formik.resetForm();
        setCouponStatus(false)
      }
      setDialogOpen(false);
    })
  };

  const handleCountdownComplete = () => {
    setCountdownCompleted(!countdownCompleted);
  };

  const getCampaignSubmissionButtonLabel = () => {
    switch (couponInformation?.coupon?.type) {
      case CampaignType.Instant:
        return IntlFormatter.getMessage("general.get_free_credit", "Get Free Credits");
      case CampaignType.LuckyDraw:
        return IntlFormatter.getMessage("general.join_the_raffle", "Join the Raffle");
    }
  }

  interface payload {
    code: string;
    domain: string;
    username?: string;
  }

  const onChangeValidateSecretCode = useCallback(async (code: string, touched: any, domain: string, username?: string) => {
    runSubmitCouponStatus(async () => {
      if (!code)
        return;

      let payload: payload = { code, domain };
      if (touched.username)
        payload.username = username;

      const result = await submitCouponStatusQuery(payload);
      const { data, error } = handleApiResponse<ValidateCouponResponse>(result);

      if (data?.result?.valid) {
        formik.setFieldError('secretCode', '');
        formik.setFieldError('username', '');
        if (touched.secretCode) {
          setCouponStatus(true);
        }
        if (touched.username) {
          setUsernameStatus(true);
        }
      }

      if (error?.data?.error.message) {
        const errorMessageKey = error?.data?.error?.message;
        const errorField = errorFieldMaping[errorMessageKey as keyof typeof errorFieldMaping];
        if (errorField === 'secretCode') {
          setCouponStatus(false);
        }
        if (errorField === 'username') {
          setUsernameStatus(false);
        }

        formik.setFieldError(errorFieldMaping[errorMessageKey as keyof typeof errorFieldMaping], errorMessages[errorMessageKey as keyof typeof errorMessages]);
      }
    })
    // eslint-disable-next-line
  }, [runSubmitCouponStatus, submitCouponStatusQuery]);

  const debouncedValidateSecretCode = useMemo(() => debounce((domain: string, touch: any, code: string, username?: string) => {
    onChangeValidateSecretCode(code, touch, domain, username)
  }, 800), [onChangeValidateSecretCode]);

  useEffect(() => {
    if (formik.values.username !== '') {
      debouncedValidateSecretCode(domain, formik.touched, formik.values.secretCode, formik.values.username);
      return;
    }
    if (formik.values.secretCode !== '') {
      debouncedValidateSecretCode(domain, formik.touched, formik.values.secretCode);
    }
    // eslint-disable-next-line
  }, [formik.values.secretCode, debouncedValidateSecretCode, domain, formik.values.username]);


  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <LanguageSwithcer defaultValue={couponInformation?.page?.defaultLanguage} />
      </Box>
      <Card sx={{ borderRadius: "8px", pb: 4 }} >
        {
          (!couponInformation?.page?.brandBanner) ? <Box display="flex" alignItems="center" justifyContent="center" p={4} sx={{ backgroundColor: "#eee", minHeight: "100px" }}><CircularProgress /></Box> :
            <Banner images={combinedImages} />
        }
        {!RedeemStatus.fully ?
          <>
            {(RedeemStatus.upcoming) && (
              <CountdownTimer
                couponInformation={couponInformation}
                startTime={startTime}
                endTime={expiry}
                onCountdownComplete={handleCountdownComplete}
                brandTitle={couponInformation?.page?.brandName}
                couponTitle={couponInformation?.coupon?.title ?? domain}
                socialMedia={couponInformation?.page?.socials}
              >
                {(couponInformation?.coupon) && (<JackpotList couponDetails={couponInformation.coupon} />)}
                {(couponInformation?.coupon?.criteria) && (
                  <CriteriaList criterias={couponInformation?.coupon?.criteria} campaignEnded={RedeemStatus.fully} />)}
              </CountdownTimer>
            )}

            {(!RedeemStatus.upcoming) && (
              <>
                <CountdownTimer
                  couponInformation={couponInformation}
                  startTime={startTime}
                  endTime={expiry}
                  onCountdownComplete={handleCountdownComplete}
                  brandTitle={couponInformation?.page?.brandName}
                  couponTitle={couponInformation?.coupon?.title ?? domain}
                  socialMedia={couponInformation?.page?.socials}
                >
                  {(couponInformation?.coupon) && (<JackpotList couponDetails={couponInformation.coupon} />)}
                  {(couponInformation?.coupon?.criteria) && (
                    <CriteriaList criterias={couponInformation?.coupon?.criteria} campaignEnded={RedeemStatus.fully} />)}
                </CountdownTimer>
                <Box px={2}>
                  <Box display="flex" py={.5} />
                  <form onSubmit={formik.handleSubmit}>
                    <Box sx={{ backgroundColor: "#A44EE81A", borderRadius: 2 }} textAlign="center" py={1} px={2}>
                      <Box display="flex" justifyContent="center" py={1}>
                        {couponInformation && (
                          <FormTitleMessage redeemStatus={RedeemStatus.invalid} couponInformation={couponInformation} />
                        )}
                      </Box>
                      <Box sx={{ width: 1, pt: 1 }}
                        display="flex"
                        flexDirection="column" >
                        <TextField
                          id="outlined-basic"
                          name="secretCode"
                          onBlur={formik.handleBlur}
                          value={formik.values.secretCode}
                          onChange={(event) => {
                            formik.handleChange(event);
                            formik.handleBlur(event);
                            setCode(event.target.value);
                            formik.setFieldTouched("username", false);
                            formik.setFieldValue("username", '');
                          }}
                          label={IntlFormatter.getMessage("general.enter_secret_code", "Enter Secret Code")}
                          variant="outlined"
                          InputProps={
                            {
                              sx: { backgroundColor: "white" },
                              inputProps: {
                                style: { textTransform: 'uppercase' }
                              },
                              endAdornment: ((!!formik.errors.secretCode) && (<Warning color="error" />)) || (loading && (<CircularProgress size={20} />))
                            }
                          }
                          InputLabelProps={{ sx: { color: "text.secondary" } }}
                          sx={styles.textField}
                          error={!!formik.touched.secretCode && !!formik.errors.secretCode}
                          helperText={!!formik.touched.secretCode && (formik.errors.secretCode ? (IntlFormatter.getMessage(formik.errors.secretCode, "Invalid Secret Code")) : '')}
                        />
                        {(isSecretCodeValid) && (
                          <Typography sx={{ color: "success.main" }} formatId="general.secret_code_is_valid">
                            Secret code is valid!
                          </Typography>
                        )}
                      </Box>
                      <BroadcastLink broadcast={couponInformation?.page} />
                    </Box>
                    <Box
                      mt={1}
                      textAlign="center"
                      display="flex"
                      flexDirection="column"
                    >
                      <TextField
                        id="outlined-basic"
                        label={`${IntlFormatter.getMessage("general.enter_your_username", `Enter your ${couponInformation?.page?.brandName ?? domain} username`, { brand: couponInformation?.page?.brandName ?? domain })}`}
                        variant="outlined"
                        sx={joinSx(styles.textField, { mx: 2, mt: 2 })}
                        InputProps={{ sx: { backgroundColor: "white" } }}
                        InputLabelProps={{ sx: { color: "text.secondary" } }}
                        onChange={(event) => {
                          formik.handleChange(event);
                          formik.handleBlur(event);
                          formik.setFieldValue("username", event.target.value);
                          setUsername(event.target.value);
                          // formik.setFieldTouched("secretCode", false);
                        }}
                        onKeyPress={(event) => {
                          if (event.key === " ") {
                            event.preventDefault(); // Prevent adding space
                          }
                        }}
                        onBlur={formik.handleBlur}
                        name="username"
                        disabled={!isSecretCodeValid}
                        value={formik.values.username}
                        error={!!formik.touched.username && !!formik.errors.username}
                        helperText={!!formik.touched.username && (formik.errors.username && IntlFormatter.getMessage(formik.errors.username + (couponInformation?.coupon?.type === CampaignType.LuckyDraw ? '_lucky' : ''), "Invalid Username"))}
                      />
                      <Box display="flex" flexDirection="row" justifyContent="center" pt={2}>
                        <Typography color="text.secondary" variant="caption" fontWeight={400} formatId="general.dont_have_username">Don't have a username?</Typography>
                        <SignupButton brandHandle={couponInformation?.page?.brandHandle} />
                      </Box>
                    </Box>
                  </form>
                </Box>
                <CardActions sx={{ mb: 2 }}>
                  <Box display="flex" textAlign="center" width={1} flexDirection='column'>

                    <Button
                      startIcon={loadingRedeem ? <CircularProgress size={20} sx={{ color: "#ffffff" }} /> : couponInformation?.coupon?.type === CampaignType.Instant ? <CoinIcon /> : <Subtract />}
                      size="large"
                      fullWidth
                      disabled={((!isSecretCodeValid || !isUsernameValid) && !loadingRedeem) || !formik.values.username.length}
                      sx={
                        joinSx(
                          styles.button,
                          {
                            background: theme.palette.primary.gradient,
                            fontWeight: 700,
                          }
                        )
                      }
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      {getCampaignSubmissionButtonLabel()}
                    </Button>
                    <BackButton url={Paths.Portal.CampaignList} label={IntlFormatter.getMessage("general.view_all_campaigns", "View All Campaigns")} />
                  </Box>
                </CardActions>
              </>
            )}
          </> : <RedeemFailed max={RedeemStatus.max ?? 0} campaign={couponInformation?.coupon?.type} broadcast={couponInformation?.page} />
        }

        {RedeemStatus.fully && (
          <Box px={2}>
            <Box display="flex" py={.5} />
            <WinnerList />
            {couponInformation?.coupon?.criteria && (<CriteriaList criterias={couponInformation?.coupon?.criteria} campaignEnded={RedeemStatus.fully} />)}
          </Box>
        )}
      </Card>

      <ConfirmDialog
        username={username}
        triggerSubmit={toSubmit}
        openFlag={dialogOpen}
        onClose={() => { setDialogOpen(false); }}
        onUsernameChange={handleUsernameChange}
        loading={loadingRedeem}
      />
      <SuccessDialog
        socialMedia={couponInformation?.page?.socials}
        creditsWon={creditWon}
        brandName={couponInformation?.page?.brandName ?? domain}
        brandHandle={couponInformation?.page?.brandSiteHandle ?? domain}
        username={username}
        secretCode={code}
        openFlag={(creditWon && couponInformation?.coupon?.type === CampaignType.Instant) ? true : false}
        onClose={() => { setCreditWon(''); }}
      />
      <LuckyDrawSuccessDialog
        socialMedia={couponInformation?.page?.socials}
        brandName={couponInformation?.page?.brandName ?? domain}
        brandHandle={couponInformation?.page?.brandSiteHandle ?? domain}
        username={username}
        secretCode={code}
        startTime={startTime}
        openFlag={(creditWon && couponInformation?.coupon?.type === CampaignType.LuckyDraw) ? true : false}
        onClose={() => { setCreditWon(''); }}
      />
      <Box pb={2} />
    </Box>
  );
};

const styles = createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    color: 'primary.contrastText',
    mx: "auto",
    lineHeight: "normal"
  },
  textField: {
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -20px) scale(0.75)',
    },
    "legend": { width: 0 },
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "transparent" }
  }

});

export default Home;