/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';

// eslint-disable-next-line no-undef
const getGTMID = () => {
  const domain = window.location.hostname;
  switch (domain) {
    case "whqprize.com":
      return process.env.REACT_APP_WINHQ_GTM_ID;
    case "s7gratis.com":
      return process.env.REACT_APP_S7_GTM_ID;
    case "xogowin.com":
      return process.env.REACT_APP_XOGOWIN_GTM_ID;
    default:
      return null;
  }
};

const GTM_ID = getGTMID();

const GoogleAnalytics = () => {
  if (!GTM_ID) return null;
  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`}
      />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GTM_ID}');
        `}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
