import React from 'react';
import { Typography } from "src/main/components";

interface SignUpButtonProps {
  brandHandle?: string;
}

const SignUpButton: React.FC<SignUpButtonProps> = ({ brandHandle }) => {
  const signUpButton = () => {
    if (brandHandle) {
      window.open(brandHandle, '_blank');
    }
  };

  return (
    <Typography
      sx={{ cursor: "pointer" }}
      pl={0.5}
      color="#5A4EE8"
      variant="caption"
      fontWeight={700}
      onClick={signUpButton}
      formatId="general.signup"
    >
      Sign Up Here
    </Typography>
  );
};

export default SignUpButton;