import moment, { Moment } from "moment";
import preval from "preval.macro";
import { Variables } from "./type";
import { decimalCommaCurrencyList } from "../constants";

export const reduceVariables = (variables: Variables, accessors: string[] = []): [string, string][] => {
  const pairs: [string, string][] = [];
  for (const key in variables) {
    const a = accessors.concat(key);
    const v = variables[key];
    if (typeof v === "number" || typeof v === "string") {
      pairs.push([a.join("."), v]);
    } else if (v instanceof moment) {
      pairs.push([a.join("."), moment(v).format("YYYY-MM-DD HH:mm:ss")]);
    } else {
      const p = reduceVariables(v, a);
      pairs.push(...p);
    }
  }
  return pairs;
}

export const wait = (duration: number) => {
  return new Promise(res => setTimeout(res, duration));
}

export const parseMoment = <T = Moment>(input: undefined | null | number | string | Date | Moment, defaultValue?: T): Moment | T | undefined => {
  if (input === null || input === undefined) return defaultValue;
  if (moment.isMoment(input)) return input as Moment;
  if (typeof input === "number") return moment.unix(input);
  if (typeof input === "string" && input.match(/^\d{0,10}$/)) return moment.unix(parseInt(input));
  try {
    return moment(input)
  } catch (error) {
    console.warn("parse moment failed");
    console.warn(error);
    return undefined;
  }
}

export enum DeployEnv {
  Production = "production",
  Staging = "staging",
  Local = "local",
}
export interface AppEnv {
  deploy: DeployEnv | null;
  api: DeployEnv | null;
  build: string;
}
export const getDeployEnv = () => {
  const hostname = window.location.hostname ?? "";
  if (hostname === "portal.gigrewards.co") {
    return DeployEnv.Production;
  } else if (hostname === "staging-portal.gigrewards.co") {
    return DeployEnv.Staging;
  } else if (hostname === "localhost") {
    return DeployEnv.Local;
  } else {
    return null;
  }
}
export const getApiEnv = () => {
  const apiEndpoint = process.env.REACT_APP_TASK_API_BASE_URI ?? "";
  if (apiEndpoint.startsWith("https://api.gigrewards.co")) {
    return DeployEnv.Production;
  } else if (apiEndpoint.startsWith("https://staging-task-api.gigrewards.co")) {
    return DeployEnv.Staging;
  } else if (apiEndpoint.startsWith("http://localhost:")) {
    return DeployEnv.Local;
  } else {
    return null;
  }
}

export const getWebsocketBaseURL = (): string | null => {
  const apiEndpoint = process.env.REACT_APP_TASK_API_BASE_WS_URI || "";
  const matchResult = apiEndpoint.match(/^https?:\/\/([^:/]+)(?::(\d+))?([^?#]+)/i);
  const protocol = apiEndpoint.startsWith("http://localhost:") ? "ws" : "wss";
  const path = matchResult && matchResult[3] + '/';
  const port = matchResult && matchResult[2] ? ':' + matchResult[2] : "";
  return matchResult ? `${protocol}://${matchResult[1]}${port}${path}` : null;
};

export const getDomain = (): string => {
  const apiEndpoint = process.env.REACT_APP_TASK_API_BASE_URI ?? "";
  return apiEndpoint.startsWith("http://localhost:") ? process.env.REACT_APP_TASK_API_BASE_DOMAIN ?? "example.com" : window.location.hostname;
};

// evaluates at yarn build
const BUILD_TIMESTAMP: string = preval`module.exports = require('dayjs')().format('YYYYMMDD/HHmmss')`
const appEnv: AppEnv = {
  deploy: getDeployEnv(),
  api: getApiEnv(),
  build: BUILD_TIMESTAMP,
}
export const getAppEnv = () => {
  return appEnv;
}

export const calculateRemainingTime = (time: string | undefined): number => {
  if (!time)
    return 0;
  const currentTime = moment();
  const futureTime = moment(time);
  const timeDifference = futureTime.diff(currentTime);
  return Math.max(0, timeDifference);
};

export const formatTime = (time: number): string => {
  const seconds = Math.floor((time / 1000) % 60);
  const minutes = Math.floor((time / 1000 / 60) % 60);
  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
  if (days < 1)
    return `${hours}h ${minutes}m ${seconds}s `;

  return `${days}d ${hours}h ${minutes}m `;
};

export const checkIfFuture = (startTime: string | undefined): boolean => {
  if (!startTime)
    return false;
  const remainingTime = calculateRemainingTime(startTime);
  return remainingTime > 1000;
};

export const checkIfExpired = (endTime: string | undefined): boolean => {
  if (!endTime)
    return false;
  const timeDifference = calculateRemainingTime(endTime);
  return timeDifference <= 1000;
};

export const calculateTotalJackpotAmount = (data: { jackpots: { amount: string, remaining: number }[] }): number => {
  return data.jackpots.reduce((total, jackpot) => total + (parseInt(jackpot.amount) * jackpot.remaining), 0);
};

export const calculateTotalJackpot = (data: { jackpots: { amount: string, remaining: number }[] }): number => {
  return data.jackpots.reduce((total, jackpot) => total + jackpot.remaining, 0);
};

export const getUniquePayCurrency = (data: { jackpots: { payCurrency: string }[] }): string | undefined => {
  const uniquePayCurrencies = new Set<string>();
  data.jackpots.forEach(jackpot => uniquePayCurrencies.add(jackpot.payCurrency));
  if (uniquePayCurrencies.size === 1) {
    return uniquePayCurrencies.values().next().value;
  }
  return undefined;
};

export const convertFormatNumber = (number: number | undefined, currency: string | undefined) => {
  if (!number)
    return 0;

  if (currency !== 'BRL') {
    return number;
  }
  return number.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatNumber = (number) => {
  return number.toLocaleString();
};

const brazilRealformatter = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

const getCurrencyFormatter = (minimumFractionDigits: number, maximumFractionDigits: number, currency?: string) => {
  return new Intl.NumberFormat(currency ?? undefined, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const formatCurrency = (number: number | undefined, currency: string | undefined) => {

  if (!number)
    return 0;

  if (currency !== 'BRL') {
    return number.toLocaleString();
  }

  return brazilRealformatter.format(number);
};


export const formatDecimalCurrency = (number: number | undefined, currency: string | undefined) => {
  if (!number)
    return 0;

  const currencyFormatter = decimalCommaCurrencyList.includes(currency ?? '')
    ? getCurrencyFormatter(2, 2, 'pt-BR')
    : getCurrencyFormatter(2, 2);

  return currencyFormatter.format(number);
};