import { Box, CardActions, CardContent } from "@mui/material";
import React from "react";
import { AnnounceImg, Alert } from 'src/assets';
import { Typography } from "src/main/components";
import { IntlFormatter } from "src/main/utils";
import { JoinUs, JoinBroadcastChannel } from "../../components";
import { CampaignType } from 'src/main/constants';
import { broadcastChannel } from "src/main/types";
interface HomeProps extends React.PropsWithChildren {
  max: number;
  campaign?: string;
  broadcast: broadcastChannel | undefined
}

const RedeemFailed: React.FC<HomeProps> = (props) => {
  const { max = 0, campaign = CampaignType.Instant, broadcast } = props;

  return (
    <Box>
      <CardContent sx={{ pt: 2, pb: 0 }}>
        <Box sx={{ backgroundColor: "rgba(229, 52, 96, 0.1)", borderRadius: 2 }} textAlign="center" py={1} px={2}>
          <Box display="flex" justifyContent="center" py={1}>
            <Alert fontSize="small" color="error" sx={{ px: .5 }} />
            {campaign === CampaignType.Instant && (
              <Typography variant="subtitle2" fontWeight={700} color="error">
                {max !== 0 && max} {IntlFormatter.getMessage("redeem.code_fully_redeemed", "secret codes fully redeemed.")}
              </Typography>
            )}
            {campaign === CampaignType.LuckyDraw && (
              <Typography variant="subtitle2" fontWeight={700} color="error">
                {IntlFormatter.getMessage("redeem.code_luckydraw_end", `The lucky draw event has ended with ${max} entries`, { max: max })}
              </Typography>
            )}
          </Box>
        </Box>

        <Box display="flex" pt={1} mb={2}>
          <Box p={1}>
            <AnnounceImg />
          </Box>
          <Box p={1}>
            <Typography formatId="redeem.better_luck" variant="h6" fontWeight={700}>
              Better luck next time
            </Typography>
            <Typography formatId="redeem.stay_tune_for_next_one" variant="body2" color="text.secondary">
              This promotion has been fully redeemed. Stay tuned on Telegram for the next one!
            </Typography>
          </Box>
        </Box>

      </CardContent>
      <CardActions>
        <JoinUs>
          <JoinBroadcastChannel broadcast={broadcast} />
        </JoinUs>
      </CardActions>
    </Box>
  );
};

export default RedeemFailed;
