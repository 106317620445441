import { Box, BoxProps } from "@mui/material";
import { Dayjs } from "dayjs";
import React from "react";
import DateInput from "src/main/components/DateInput";
import { createStyles } from "src/main/utils";

// type FilterDateType = "start" | "end";
// const filterDateType: SimpleMap<FilterDateType> = {
//   start: "start",
//   end: "end",
// }

interface DateFilterProps extends BoxProps {
  onStartDateChange: (date: Dayjs | null) => void;
  onEndDateChange: (date: Dayjs | null) => void;
  startLabel?: string;
  endLabel?: string;
  startValue: Dayjs | null;
  endValue: Dayjs | null;
}

const DateFilterComponent: React.FC<DateFilterProps> = (props) => {
  const {
    startLabel = "Start", endLabel = "End",
    startValue, endValue, onStartDateChange, onEndDateChange
  } = props;


  return (
    <Box display="flex" flex={1} gap={2}>
      <Box flex={1}>
        <DateInput
          label={startLabel}
          value={startValue}
          handleChange={onStartDateChange}
          sx={styles.dateInput}
        />
      </Box>
      <Box flex={1}>
        <DateInput
          label={endLabel}
          value={endValue}
          handleChange={onEndDateChange}
          sx={styles.dateInput}
        />
      </Box>
    </Box>
  );
};

const styles = createStyles({
  dateInput: {
    width: {
      sm: "inherit",
      lg: "auto",
    }
  }
})

export default DateFilterComponent;
