import { Box, BoxProps } from "@mui/material";
import React from "react";
import { createStyles } from "src/main/utils";

interface ContentProps extends React.PropsWithChildren, BoxProps {

}

const Content: React.FC<ContentProps> = (props) => {
  const { children, ...boxProps } = props;
  return (
    <Box {...boxProps} sx={styles.root}>
      {children}
    </Box>
  );
};

const styles = createStyles({
  root: {
    paddingTop: 2,
  },
});

export default Content;
