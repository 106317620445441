import { createSvgIcon } from '@mui/material/utils';

const ChevronRight = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.79289 3.45952C7.18342 3.06899 7.81658 3.06899 8.20711 3.45952L14.0404 9.29285C14.431 9.68338 14.431 10.3165 14.0404 10.7071L8.20711 16.5404C7.81658 16.9309 7.18342 16.9309 6.79289 16.5404C6.40237 16.1499 6.40237 15.5167 6.79289 15.1262L11.9191 9.99996L6.79289 4.87373C6.40237 4.48321 6.40237 3.85004 6.79289 3.45952Z" fill="currentColor" />
  </svg>
  ,
  'ChevronRight'
);

export default ChevronRight;
