import BigNumber from "bignumber.js";
import { BN_ZERO } from "../constants";

export const numberOrZero = (input: any): number => {
  switch (typeof input) {
    case "number": return input;
    default:
      return isNumber(input) ? Number(input) : 0;
  }
};

export const isNumber = (input: any): boolean => {
  return !isNaN(input) && isFinite(input);
}

export const parseBN = (input?: string | BigNumber | number | null, defaultValue?: BigNumber) => {
  if (!input && input !== 0) return defaultValue;
  const result = BigNumber.isBigNumber(input) ? input : new BigNumber(input);
  if (!result.isFinite() || result.isNaN())
    return defaultValue;

  return result;
};

export const bnOrZero = (input?: string | BigNumber | number | null, defaultValue: BigNumber = BN_ZERO) => {
  return parseBN(input, defaultValue)!;
};

export const charactersStr = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16)
  })
}
