import { Typography, TypographyProps } from "@mui/material";
import { CSSProperties } from '@mui/material/styles/createTypography';
import React from "react";

interface BorderedTypographyProps extends TypographyProps {
  backgroundGradient?: boolean
}

const BorderedTypography: React.FC<BorderedTypographyProps> = (props) => {
  const { children, backgroundGradient, ...rest } = props;
  const gradientStyle: CSSProperties = {
    background: backgroundGradient ? 'linear-gradient(180deg, #D89B5F 0%, #FAD392 47.01%, #D89B5F 50.47%, #7E4D1C 95%)' : '',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: backgroundGradient ? 'transparent' : 'currentColor',
    WebkitTextStroke: '1.5px white',
    textStroke: '1.5px white',
    fontFamily: "Satoshi",
    fontStyle: 'normal',
    fontWeight: '900',
    textShadow: '0px 4px 0px rgba(0, 0, 0, 0.16)',
  };

  return (
    <Typography style={gradientStyle} {...rest}>
      {children}
    </Typography>
  );
};

export default BorderedTypography;
