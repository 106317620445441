import { createSvgIcon } from '@mui/material/utils';

const Check = createSvgIcon(
  <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6895 8.49414C16.6895 12.9124 13.1077 16.4941 8.68945 16.4941C4.27118 16.4941 0.689453 12.9124 0.689453 8.49414C0.689453 4.07586 4.27118 0.494141 8.68945 0.494141C13.1077 0.494141 16.6895 4.07586 16.6895 8.49414ZM12.7198 5.46381C12.4269 5.17092 11.952 5.17092 11.6591 5.46381C11.652 5.47088 11.6454 5.47838 11.6392 5.48625L8.16685 9.91088L6.07333 7.81736C5.78044 7.52447 5.30556 7.52447 5.01267 7.81736C4.71978 8.11026 4.71978 8.58513 5.01267 8.87802L7.65912 11.5245C7.95201 11.8174 8.42688 11.8174 8.71978 11.5245C8.7263 11.5179 8.73246 11.5111 8.73822 11.5039L12.7304 6.51361C13.0126 6.21996 13.0091 5.75311 12.7198 5.46381Z" fill="currentColor" />
  </svg>
  ,
  'Check'
);

export default Check;