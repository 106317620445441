import { createSvgIcon } from '@mui/material/utils';

const Clock = createSvgIcon(
  <svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6895 8.49414C16.6895 12.9124 13.1077 16.4941 8.68945 16.4941C4.27117 16.4941 0.689453 12.9124 0.689453 8.49414C0.689453 4.07586 4.27117 0.494141 8.68945 0.494141C13.1077 0.494141 16.6895 4.07586 16.6895 8.49414ZM8.68945 3.99414C8.68945 3.718 8.4656 3.49414 8.18945 3.49414C7.91331 3.49414 7.68945 3.718 7.68945 3.99414V9.49414C7.68945 9.67357 7.7856 9.83924 7.94138 9.92826L11.4414 11.9283C11.6811 12.0653 11.9866 11.982 12.1236 11.7422C12.2606 11.5025 12.1773 11.197 11.9375 11.06L8.68945 9.20398V3.99414Z" fill="currentColor" />
  </svg>

  ,
  'Clock'
);

export default Clock;