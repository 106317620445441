import { createSvgIcon } from '@mui/material/utils';

const Warning = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.2269 0.958027C10.672 0.013991 9.33182 0.013991 8.77697 0.958027L0.205782 15.5414C-0.365509 16.5134 0.320348 17.75 1.43074 17.75H18.5731C19.6835 17.75 20.3694 16.5134 19.7981 15.5414L11.2269 0.958027ZM10 5.25C10.6693 5.25 11.1922 5.82786 11.1256 6.4938L10.6872 10.8781C10.6519 11.2311 10.3548 11.5 10 11.5C9.64519 11.5 9.34811 11.2311 9.31281 10.8781L8.87438 6.4938C8.80779 5.82786 9.33074 5.25 10 5.25ZM10.0019 12.75C10.6923 12.75 11.2519 13.3096 11.2519 14C11.2519 14.6904 10.6923 15.25 10.0019 15.25C9.31157 15.25 8.75193 14.6904 8.75193 14C8.75193 13.3096 9.31157 12.75 10.0019 12.75Z" fill="currentColor" />
  </svg>,
  'Warning'
);

export default Warning;
