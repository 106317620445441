import { Box, BoxProps, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import React from "react";
import { createStyles, joinSx } from "src/main/utils";
interface Props extends BoxProps {
  label: string;
  value: Dayjs | null;
  handleChange: (val: Dayjs | null) => void;
  disablePast?: boolean;
  disableFuture?: boolean;
  disabled?: boolean;
}

const DateInput: React.FC<Props> = (props: Props) => {
  const { value, label, handleChange, disablePast = false, disableFuture = false, disabled = false, children, sx, ...rest } = props;

  // const [value, setValue] = React.useState<Dayjs | null>(
  //   dayjs(),
  // );

  const onChange = (newValue: Dayjs | null) => {
    handleChange(newValue);
  };
  return (
    <Box  {...rest} sx={joinSx(styles.root, sx)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="DD/MM/YYYY"
          label={label}
          value={value}
          onChange={onChange}
          disableFuture={disableFuture}
          disablePast={disablePast}
          disabled={disabled}
          renderInput={(params) => <TextField InputLabelProps={{ shrink: true, }} fullWidth {...params} />}
        />
      </LocalizationProvider>
    </Box>
  );
};

const styles = createStyles({
  root: {
  },
});

export default DateInput;
