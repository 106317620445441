import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, IconButton, useTheme } from "@mui/material";
import { useSelector } from 'react-redux';
import { ArrowRight, IconX, LuckyDrawCoin } from "src/assets";
import { BorderedTypography, Typography } from "src/main/components";
import { RootState } from 'src/main/store';
import { IntlFormatter, browserLanguageSetting } from "src/main/utils";
import { ContactUs } from "../../components";
import { socialMediaModel } from "src/main/types";

interface ConfirmDialogProps extends Omit<DialogProps, "open"> {
  brandName: string;
  username: string;
  brandHandle: string;
  secretCode: string;
  openFlag?: boolean;
  startTime: string | undefined;
  socialMedia: socialMediaModel[] | undefined;
}
const SuccessDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { brandName, brandHandle, secretCode, username, openFlag = false, startTime, onClose, socialMedia, ...dialogProps } = props;
  const theme = useTheme();
  const language = useSelector<RootState, string | null>(state => state.preference.language) ?? browserLanguageSetting;

  const _onClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    onClose?.(event, reason);
  }

  return (
    <Dialog
      fullWidth
      {...dialogProps}
      open={openFlag}
      onClose={_onClose}
      sx={{ ".MuiDialog-paper": { overflow: "visible" } }
      }>
      <Box
        sx={{
          zIndex: 10000,
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={-15}
      >
        <Box
          component="img"
          src={LuckyDrawCoin}
        />
        <Box sx={{ position: "absolute" }} textAlign="center">
          <BorderedTypography fontSize={(language === "th") ? "38px" : "28px"} lineHeight="24px">{IntlFormatter.getMessage("dialog.lucky_draw_entry", "ENTRY")}</BorderedTypography>
          <BorderedTypography pt={1} fontSize={(language === "th") ? "34px" : "24px"} lineHeight="20px" color="text.primary">{IntlFormatter.getMessage("dialog.lucky_draw_submitted", "SUBMITTED")}</BorderedTypography>
        </Box>

      </Box>
      <Box sx={{
        position: "absolute",
        zIndex: 10000,
        right: -10, top: -10,
      }}>
        <IconButton
          onClick={() => _onClose({}, "escapeKeyDown")}
          sx={
            {
              backgroundColor: "white", borderRadius: 10, boxShadow: 8, p: .5,
              ":hover": {
                backgroundColor: "white"
              }
            }
          }>
          <IconX fontSize="small" />
        </IconButton>
      </Box>
      <DialogContent sx={{ marginTop: -5 }}>
        <Typography formatId="dialog.success_submit_luckydraw" textAlign="center" fontSize={20} fontWeight={700}>
          Stay Tuned for the Prize Announcement
        </Typography>
        <Typography
          color="text.secondary"
          textAlign="center"
          variant="body2"
          formatId="dialog.lucky_draw_price_announcement"
          formatValues={{ brandName: brandName }}
          defaultMessage={`The ${brandName} site will notify you of the lucky draw result`}
        >
        </Typography>

      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 4 }}>
        <Button
          href={brandHandle}
          startIcon={<ArrowRight />}
          variant="contained"
          sx={{ background: theme.palette.primary.gradient, fontWeight: 700, boxShadow: 10 }}
        >
          {IntlFormatter.getMessage("dialog.go_to_site", `Go to ${brandName} site`, { brandName: brandName })}
        </Button>
      </DialogActions>
      <Box pb={4} >
        <ContactUs secretCode={secretCode} type="lucky-draw" socialMedia={socialMedia} />
      </Box>
    </Dialog>
  );
}

export default SuccessDialog;
