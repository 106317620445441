import { Notifications } from '@mui/icons-material';
import { Box, TextField } from "@mui/material";
import { Form, Formik, FormikValues } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Check, Info } from 'src/assets';
import { publicApi } from "src/main/api";
import { GradientButton, Typography } from "src/main/components";
import { IntlFormatter, errorMessages, getDomain } from "src/main/utils";
import * as Yup from "yup";

interface EmailReminderProps extends React.PropsWithChildren {
  secretCode?: string
  brandTitle?: string
  startTime?: string
  type?: string
}

const EmailReminder: React.FC<EmailReminderProps> = (props) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitEmailSubscribe, { isLoading: isSubmitLoading }] = publicApi.useSubscribeMutation();
  const { handleKey = "" } = useParams<{ handleKey: string }>();
  const domain = getDomain();
  const successMessage = IntlFormatter.getMessage("reminder.6_hours_in_advance_plus_30_minutes_reminder", "A 6-hour, 30-minute reminder email will be sent before the campaign starts.")
  const initial_state = useMemo(() => { return { email: "", } }, [])

  const onSubmit = useCallback(async (values: FormikValues) => {
    const submitData = {
      domain: domain,
      handle: handleKey,
      email: values.email,
    }
    const result = await submitEmailSubscribe(submitData);
    if ("data" in result) {
      toast.success(successMessage, { icon: <Info color="primary" /> });
      setIsSuccess(true)
    } else {
      toast.error(<Typography formatId={errorMessages[(result.error as any).data?.error?.message as keyof typeof errorMessages]} defaultMessage={(result.error as any).data?.error?.message} />);
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box textAlign="center" p={2}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography formatId="reminder.dont_miss_it" defaultMessage="Don't miss it!" variant="body2" fontWeight={700} color="error">&nbsp;
          <Typography formatId="reminder.get_and_email_reminder" variant="caption" color="text.primary" fontSize={14} fontWeight={700}>Get an email reminder</Typography>
        </Typography>
      </Box>
      <Typography formatId="reminder.6_hours_in_advance_plus_30_minutes_heads_up" variant="caption" color="text.secondary" display="block" lineHeight={1}> 6 hours in advance, plus a 30-minute heads-up</Typography>
      <Formik
        initialValues={initial_state}
        enableReinitialize
        validationSchema={Yup.object().shape({
          email: Yup.string().email('general.invalid_email').required('general.email_required'),
        })}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          validateForm,
          touched,
          errors,
        }): JSX.Element => (
          <Form onSubmit={(e) => {
            validateForm().then((err: any) => {
              Object.keys(err).forEach((err) => {
              });
            });
            handleSubmit(e);
          }}>
            <Box display="flex" justifyContent="center" mt={1} sx={{ height: 48 }}>
              <TextField
                name="email"
                variant="outlined"
                fullWidth
                required
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.email && !!touched.email}
                disabled={isSuccess}
                placeholder={IntlFormatter.getMessage("reminder.enter_your_best_email", "Enter your best email")}
                sx={{
                  '& input:-webkit-autofill': {
                    'padding': 1
                  },
                }}
                InputProps={
                  {
                    sx: {
                      backgroundColor: "white",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 8,
                      borderBottomLeftRadius: 8,
                      borderRight: 0,
                      padding: 0,
                      height: 48,
                    },
                  }
                }
              />
              <GradientButton
                disabled={isSuccess || isSubmitLoading}
                sx={{
                  padding: 0,
                  width: {
                    xs: 160,
                    sm: 250,
                  },
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  fontSize: {
                    xs: 14,
                    sm: 16,
                  },
                }}
                variant="contained" type="submit"
              >
                {isSuccess ? <Check sx={{
                  pr: .5,
                  fontSize: {
                    xs: 18,
                    sm: 23,
                  }
                }} /> :
                  <Notifications sx={{
                    fontSize: {
                      xs: 20,
                      sm: 25,
                    }
                  }} />
                }
                {isSuccess ? IntlFormatter.getMessage("reminder.reminded", "Reminded") : IntlFormatter.getMessage("reminder.remind_me", "Remind Me")}
              </GradientButton>
            </Box>
            {(!!errors.email && !!touched.email) && <Typography lineHeight={1} color="error">{IntlFormatter.getMessage(errors.email, errors.email)}</Typography>}
          </Form>
        )}
      </Formik>

    </Box >
  );
};

export default EmailReminder;