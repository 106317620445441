import createSagaMiddleware from "@redux-saga/core";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import preferenceSlice from "./preference/slice";
import layoutSlice from "./layout/slice";
import mainSaga from "./saga";
import couponInformationApi from "../api/public";

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  preference: preferenceSlice.reducer,
  layout: layoutSlice.reducer,
  //couponInformationApi: couponInformationApi.reducer,
  [couponInformationApi.reducerPath]: couponInformationApi.reducer,
})


const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: true,
    serializableCheck: false,
  }).concat(couponInformationApi.middleware).concat([sagaMiddleware])
});

sagaMiddleware.run(mainSaga);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
