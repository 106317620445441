import { createSvgIcon } from '@mui/material/utils';

const MoonActiveIcon = createSvgIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.44219 1.0283C6.62439 1.25044 6.68659 1.58521 6.52259 1.88613C5.962 2.91474 5.6434 4.09302 5.6434 5.34686C5.6434 9.3673 8.92219 12.6227 12.9621 12.6227C13.4884 12.6227 14.0013 12.5675 14.4953 12.4629C14.8331 12.3913 15.1403 12.544 15.305 12.7786C15.4749 13.0207 15.5105 13.3818 15.2737 13.6724C13.7453 15.5485 11.4067 16.75 8.78546 16.75C4.17532 16.75 0.441895 13.0363 0.441895 8.46002C0.441895 5.0158 2.55604 2.06197 5.56543 0.810088C5.91313 0.665449 6.25418 0.799093 6.44219 1.0283Z"
            fill="url(#paint0_linear_776_487)"
        />
        <defs>
            <linearGradient id="paint0_linear_776_487" x1="0.558098" y1="16.75" x2="11.3066" y2="16.1951" gradientUnits="userSpaceOnUse">
                <stop stopColor="#6A0FDE" />
                <stop offset="1" stopColor="#579AFF" />
            </linearGradient>
        </defs>
    </svg>,
    'MoonActiveIcon'
);

export default MoonActiveIcon;
