import { Middleware } from "@reduxjs/toolkit";
import publicApi from "./public";

export const apiReducers = {
  [publicApi.reducerPath]: publicApi.reducer,
};

export const apiMiddlewares: Middleware[] = [
  publicApi.middleware,
];

export {
  publicApi,
};