import { Box } from "@mui/material";
import { NormalPrize } from 'src/assets';
import { Typography } from "src/main/components";
import { JackpotModel } from "src/main/types";
import { createStyles, joinSx, formatDecimalCurrency } from "src/main/utils";

interface prizeList extends JackpotModel {
  minValue?: number;
  maxValue?: number;
}

interface JackpotListItemProps {
  prizeItem: prizeList;
}

const JackpotListItem: React.FC<JackpotListItemProps> = (props) => {
  const { prizeItem } = props;
  const normalPrize = () => {
    if (prizeItem.amount)
      return 0;
    return prizeItem.minValue === prizeItem.maxValue ? formatDecimalCurrency(prizeItem.minValue, prizeItem.payCurrency) : `${formatDecimalCurrency(prizeItem.minValue, prizeItem.payCurrency)} - ${formatDecimalCurrency(prizeItem.maxValue, prizeItem.payCurrency)}`;
  };

  return (
    <Box mt={1}>
      <Box p={1}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={joinSx(styles.itemBox)}>
            <Box component="img" src={NormalPrize} sx={{ width: '30px', maxHeight: 80 }} />
            <Box px={1}>
              <Box sx={joinSx(styles.itemBox)}>
                <Typography variant="body2" fontWeight={700} display="block" lineHeight={0.85}>
                  {prizeItem.amount ? `${formatDecimalCurrency(parseInt(prizeItem.amount), prizeItem.payCurrency)}` : `${normalPrize()}`} {prizeItem.payCurrency}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={joinSx(styles.itemBox)}>
            <Box display="flex" minWidth={60}>
              <Typography
                formatId="lottery.limit"
                defaultMessage={`Limit: ${prizeItem.remaining}`}
                formatValues={{ number: prizeItem.remaining }}
                variant="caption" display="block" lineHeight={0.85} color="text.secondary">
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box >
  );
}

const styles = createStyles({
  itemBox: {
    display: "flex",
    alignItems: "center",
  },
  statusIcon: {
    fontSize: 16,
    px: .5,
    lineHeight: 0.85,
  }
});

export default JackpotListItem;