import { fork } from "@redux-saga/core/effects";

function* init() {
}

function* saga() {
  yield fork(init)
}

export default saga;
