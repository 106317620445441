import { Box, IconButton, styled } from "@mui/material";
import React, { useMemo } from "react";
import { FacebookMessengerShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton, LineShareButton } from "react-share";
import { Telegram, Twitter, Whatsapp, Line, Facebook } from 'src/assets';
import { Typography } from "src/main/components";
import { createStyles, calculateRemainingTime, formatTime, IntlFormatter } from "src/main/utils";
import { socialMediaModel } from "src/main/types";

interface ContactUsProps extends React.PropsWithChildren {
  secretCode?: string
  brandTitle?: string
  startTime?: string
  type?: string
  socialMedia: socialMediaModel[] | undefined
}

const CustomTypography = styled(Typography)({
  fontFamily: 'Satoshi',
});

const ContactUs: React.FC<ContactUsProps> = (props) => {
  const { secretCode = "", brandTitle = "Secret Code", startTime = "very soon", type = "instant", socialMedia } = props;
  const url = window.location.href;

  const shareSecretCodeMessage = `🤯🤯 ${IntlFormatter.getMessage("contact.default_message.start", "Get free credits now with secret code:")} ${secretCode}\n\n${IntlFormatter.getMessage("contact.default_message.mid", "Limited quantities available, act fast!")}\n\n${IntlFormatter.getMessage("contact.default_message.end", "Redeem here:")} ${url}`;
  const shareFreeCeditMessage = `🤯🤯 ${IntlFormatter.getMessage("contact.who_want_free_credits", "Who wants free credits?")} \n\n${brandTitle} ${IntlFormatter.getMessage("contact.dropping_free_credit_in", "is dropping free credits! Starting in")} ${formatTime(calculateRemainingTime(startTime))}. ${IntlFormatter.getMessage("contact.default_message.end", "Redeem here:")} ${url} \n\n${IntlFormatter.getMessage("contact.limited_quantities_snatch_it", "Limited quantities available, snatch it before it's gone!")}`;
  const shareLuckyDrawMessage = `🤯🤯 ${IntlFormatter.getMessage("contact.join_this_lucky_draw", "Join this Lucky Draw with Secret Code:")} ${secretCode}\n\n ${IntlFormatter.getMessage("contact.hurry_event_ends_soon", "Hurry! Event ends soon. Join here:")} ${url}`;

  const defaultMessage = useMemo(() => {
    if (secretCode)
      if (type === "instant")
        return shareSecretCodeMessage;
      else
        return shareLuckyDrawMessage;
    else
      return shareFreeCeditMessage;
  }, [shareSecretCodeMessage, shareFreeCeditMessage, shareLuckyDrawMessage, secretCode, type]);

  const getSocialMediaIcon = (socialMedia: socialMediaModel) => {
    switch (socialMedia.type) {
      case "twitter":
        return <TwitterShareButton url={"."} title={defaultMessage}>
          <IconButton sx={styles.myIconButton}><Twitter fontSize="large" sx={{ color: "#1D9BF0" }} /></IconButton>
        </TwitterShareButton>
      case "telegram":
        return <TelegramShareButton url={"Secret Code"} title={defaultMessage}>
          <IconButton sx={styles.myIconButton}><Telegram fontSize="large" sx={{ color: "#2199D4" }} /></IconButton>
        </TelegramShareButton>
      case "line":
        return <LineShareButton url={url} title={defaultMessage}>
          <IconButton sx={styles.myIconButton}><Line fontSize="large" sx={{ color: "#00B900" }} /></IconButton>
        </LineShareButton>
      case "facebook":
        return <FacebookMessengerShareButton url={url} appId={"c"}>
          <IconButton sx={styles.myIconButton}><Facebook fontSize="large" sx={{ color: "#0084FF" }} /></IconButton>
        </FacebookMessengerShareButton>
      case "instagram":
        return
      case "whatsapp":
        return <WhatsappShareButton url={"."} title={defaultMessage}>
          <IconButton sx={styles.myIconButton}><Whatsapp fontSize="large" sx={{ color: "#3FC350" }} /></IconButton>
        </WhatsappShareButton>
    }
  };

  return (
    <Box textAlign="center" pt={2}>
      {socialMedia?.length !== 0 && <CustomTypography lineHeight={1} variant="body2" color="text.secondary">{IntlFormatter.getMessage("countdown.spread_love", "Spread the love:")}</CustomTypography>}
      <Box display="flex" justifyContent="center" p={1}>
        {socialMedia && socialMedia.map((socialMedia: socialMediaModel, index) => (
          <Box key={index}>{getSocialMediaIcon(socialMedia)}</Box>
        ))}
      </Box>
    </Box>
  );
};

const styles = createStyles({
  myIconButton: {
    backgroundColor: "white",
    mx: .6,
    '&:hover': {
      backgroundColor: 'white',
    }
  },

});

export default ContactUs;