import { createSvgIcon } from "@mui/material/utils";

export const GbIcon = createSvgIcon(
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2603_41439)">
      
      <g mask="url(#mask0_2603_41439)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-3.56714 0.0157471H25.0484V20.4554H-3.56714V0.0157471Z" fill="url(#paint1_linear_2603_41439)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-3.56458 0.0157471H25.0509V20.4554H-3.56458V0.0157471Z" fill="url(#paint2_linear_2603_41439)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-3.56714 0.0157471H25.0484V20.4554H-3.56714V0.0157471Z" fill="url(#paint3_linear_2603_41439)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.25333 13.6421H-3.56711V6.82892H3.25333L-6.40173 0.316494L-4.87777 -1.94287L7.33403 6.2941V-1.34694H14.1472V6.2941L26.3591 -1.94287L27.883 0.316494L18.2279 6.82892H25.0484V13.6421H18.2279L27.883 20.1546L26.3591 22.4139L14.1472 14.177V21.818H7.33403V14.177L-4.87777 22.4139L-6.40173 20.1546L3.25333 13.6421Z" fill="url(#paint4_linear_2603_41439)" />
        <path d="M15.6983 6.77176L28.6421 -1.7436C28.7993 -1.847 28.8429 -2.05825 28.7395 -2.21543C28.6361 -2.3726 28.4248 -2.4162 28.2677 -2.31279L15.3239 6.20256C15.1667 6.30596 15.1231 6.51721 15.2265 6.67439C15.3299 6.83156 15.5412 6.87516 15.6983 6.77176Z" fill="#DB1F35" />
        <path d="M16.6951 14.2969L28.2985 22.1179C28.4545 22.2231 28.6662 22.1819 28.7714 22.0259C28.8766 21.8699 28.8353 21.6581 28.6793 21.553L17.0759 13.7319C16.9199 13.6268 16.7082 13.668 16.603 13.824C16.4979 13.98 16.5391 14.1917 16.6951 14.2969Z" fill="#DB1F35" />
        <path d="M4.80472 6.18238L-7.29863 -1.97451C-7.45465 -2.07966 -7.66637 -2.03842 -7.77151 -1.8824C-7.87666 -1.72639 -7.83542 -1.51467 -7.6794 -1.40952L4.42395 6.74737C4.57997 6.85252 4.79169 6.81128 4.89683 6.65526C5.00198 6.49924 4.96074 6.28753 4.80472 6.18238Z" fill="#DB1F35" />
        <path d="M5.73881 13.6358L-7.67729 22.5327C-7.83408 22.6367 -7.8769 22.8481 -7.77292 23.0049C-7.66894 23.1617 -7.45754 23.2045 -7.30074 23.1005L6.11536 14.2036C6.27215 14.0996 6.31497 13.8882 6.21099 13.7314C6.10701 13.5746 5.89561 13.5318 5.73881 13.6358Z" fill="#DB1F35" />
        <path fillRule="evenodd" clipRule="evenodd" d="M-3.56714 12.2795H8.69665V20.4554H12.7846V12.2795H25.0484V8.19161H12.7846V0.0157471H8.69665V8.19161H-3.56714V12.2795Z" fill="url(#paint5_linear_2603_41439)" />
      </g>
    </g>
    <defs>
      <linearGradient id="paint0_linear_2603_41439" x1="210.5" y1="0.0899658" x2="210.5" y2="300.09" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0A17A7" />
        <stop offset="1" stopColor="#030E88" />
      </linearGradient>
      <linearGradient id="paint1_linear_2603_41439" x1="296.896" y1="0.0157471" x2="296.896" y2="306.61" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0A17A7" />
        <stop offset="1" stopColor="#030E88" />
      </linearGradient>
      <linearGradient id="paint2_linear_2603_41439" x1="296.898" y1="0.0157471" x2="296.898" y2="306.61" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient id="paint3_linear_2603_41439" x1="296.896" y1="0.0157471" x2="296.896" y2="306.61" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0A17A7" />
        <stop offset="1" stopColor="#030E88" />
      </linearGradient>
      <linearGradient id="paint4_linear_2603_41439" x1="353.588" y1="-1.94287" x2="353.588" y2="363.409" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F0F0F0" />
      </linearGradient>
      <linearGradient id="paint5_linear_2603_41439" x1="296.896" y1="0.0157471" x2="296.896" y2="306.61" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E6273E" />
        <stop offset="1" stopColor="#CF152B" />
      </linearGradient>
      <clipPath id="clip0_2603_41439">
        <rect x="0.5" y="0.0899658" width="20" height="20" rx="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
  ,
  'GbIcon'
);

export default GbIcon;

