import React from "react";
import { Route, Routes } from "react-router-dom";
import { LandingLayout } from "src/main/components";
import { Paths, makeRelativePath } from "src/main/utils";
import HomePage from "./HomePage";

interface MainProps extends React.PropsWithChildren {

}
const Main: React.FC<MainProps> = (props) => {
  const p = makeRelativePath(Paths.Portal.Index);
  
  return (
    <LandingLayout.Container>
      <LandingLayout.Content>
        <Routes>
          <Route path={p(Paths.Portal.Home)} element={<HomePage />} />
          {/* <Route path={p(Paths.Portal.NotFound)} element={<PageNotFound />} /> */}
          {/* <Route path="/" element={<PageNotFound />} /> */}
        </Routes>
      </LandingLayout.Content>
    </LandingLayout.Container>

  );
};

export default Main;
