import { FormattedTime, FormattedDateParts } from 'react-intl';
import { BrIcon, GbIcon, ThIcon } from "src/assets";
import English from 'src/lang/en.json';
import Portugese from 'src/lang/pt.json';
import Thai from 'src/lang/th.json';

export const browserLanguageSetting = navigator.language.split(/[-_]/)[0];
// export const browserLanguageSetting = "en"; //FOR TESTING

type MessageTranslations = {
  [key: string]: string;
};

export const messages: Record<string, MessageTranslations> = {
  en: English,
  pt: Portugese,
  th: Thai,
};

export const getLanguageLabel = (value: string) => {
  const languageOption = LANGUAGE_OPTIONS.find(option => option.value === value);
  return { label: languageOption ? languageOption.label : '', icon: languageOption ? languageOption.icon : '' }
};
export const LANGUAGE_OPTIONS = [
  { value: "en", label: "English", icon: <GbIcon fontSize='small' /> },
  { value: "pt", label: "Português", icon: <BrIcon fontSize='small' /> },
  { value: "th", label: "ภาษาไทย", icon: <ThIcon fontSize='small' /> },
]

export const localizationDate = (value: Date) => {
  return <>
    <FormattedDateParts
      value={value}
      year="numeric"
      month="long"
      day="2-digit"
    >
      {parts => (
        <>{parts[2].value}{parts[1].value}{parts[0].value}{parts[1].value}{parts[4].value}</>
      )}
    </FormattedDateParts>
  </>
};

export const localizationTime = (value: Date) => {
  return <>
    <FormattedTime value={value} />
  </>
};